import './Nav.scss'
import React from 'react';

export default function Nav (props) {
    return (
        <div className="container-nav">
            <span className="container-info">
                <h3>{ props.serie }</h3>
                <h4>{ props.version }</h4>
            </span>
            <span className="container-fecha">
                <h3>{ props.fecha }</h3>
            </span>
        </div>
    )
}

