import "./Registrocliente.scss";
import React, { useEffect, useState } from "react";
import { useForm } from "../../hooks/useForm";
import { AuthData } from "../../data/AuthData";


export default function RegistroCliente() {

  AuthData();
  const [datoRegistro, setDatoRegistro] = useState({})
  const [certificado, setCertificado] = useState({})
  const [formValues, handleInputChange] = useForm({
    Cliente: "",
    Nit: '',
    Ciudad: "",
    Nodeinforme: "",
    Dependencia: "",
    Contacto: "",
    Cargo: "",
    Nombreparacertificado: "",
    Direccion: "",
    Ciudadcertificado: "",
    TypeCalification: '',
  });
  const {
    Cliente,
    Nit,
    Ciudad,
    Nodeinforme,
    Dependencia,
    Contacto,
    Cargo,
    Nombreparacertificado,
    Direccion,
    Ciudadcertificado,
    TypeCalification,
  } = formValues;
  const datosRegistro = {
    Cliente: formValues.Cliente,
    Nit: formValues.Nit,
    Ciudad: formValues.Ciudad,
    Contacto: formValues.Contacto,
    Dependencia: formValues.Dependencia,
    Cargo: formValues.Cargo,
  }
  const datosCertificado = {
    Nombreparacertificado: formValues.Nombreparacertificado,
    Direccion: formValues.Direccion,
    Ciudadcertificado: formValues.Ciudadcertificado,
    TypeCalification: formValues.TypeCalification,
  }
  const handleSubmit = (e) => {
    e.preventDefault();
}

const  handleClick = (e) => {
  e.preventDefault();
    setDatoRegistro(datosRegistro);
    setCertificado(datosCertificado)
}
console.log(datoRegistro);
console.log(certificado);
console.log(Nodeinforme);

const baseUrl = "https://calificaciones.bioredingenieria.com/api/";
  const [postApi, setPostApi] = useState([]);
  useEffect(() => {
    const requireOptions = {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };
    fetch(`${baseUrl}cliente`, requireOptions)
    .then(response => response.json())
    .then(data => setPostApi(data))
  },[baseUrl]);
  console.log(postApi);


if (TypeCalification === 'Desempeño') {
  console.log('certificacion desempeño');
}else {
  console.log('certificacion operacional');
}
  return (
    <div className="container-registro">
      <form className="form-registro" name="formulario" onChange={ handleSubmit }>
        <label htmlFor="Cliente">Cliente</label>
        <input
          type="text"
          id="Cliente"
          placeholder="Ingrese Cliente"
          name="Cliente"
          value={ Cliente }
          onChange={handleInputChange}
        />
        <label htmlFor="Nit">Nit</label>
        <input
          type="text"
          id="Nit"
          placeholder="Ingrese Nit"
          name="Nit"
          value={ Nit }
          onChange={handleInputChange}
        />
        <label htmlFor="Ciudad">Ciudad</label>
        <input
          type="text"
          id="Ciudad"
          placeholder="Ingrese Ciudad"
          name="Ciudad"
          value={ Ciudad }
          onChange={handleInputChange}
        />

        <label htmlFor="No-informe">No de informe</label>
        <input
          type="text"
          id="No-informe"
          placeholder="Ingrese # de informe"
          name="Nodeinforme"
          value={ Nodeinforme }
          onChange={handleInputChange}
        />

        <label htmlFor="Dependencia">Dependencia</label>
        <input
          type="text"
          id="Dependencia"
          placeholder="Ingrese Dependencia"
          name="Dependencia"
          value={ Dependencia }
          onChange={handleInputChange}
        />

        <label htmlFor="Contacto">Contacto</label>
        <input
          type="text"
          id="Contacto"
          placeholder="Ingrese Contacto"
          name="Contacto"
          value={ Contacto }
          onChange={handleInputChange}
        />

        <label htmlFor="Cargo">Cargo</label>
        <input
          type="text"
          id="Cargo"
          placeholder="Ingrese Cargo"
          name="Cargo"
          value={ Cargo }
          onChange={handleInputChange}
        />

        <label htmlFor="Nombre-para-certificado">Nombre certificado</label>
        <input
          type="text"
          id="Nombre-para-certificado"
          placeholder="Nombre completo para certificado"
          name="Nombreparacertificado"
          value={ Nombreparacertificado }
          onChange={handleInputChange}
        />

        <label htmlFor="Direccion">Direccion</label>
        <input
          type="text"
          id="Direccion"
          placeholder="Ingrese Direccion"
          name="Direccion"
          value={ Direccion }
          onChange={handleInputChange}
        />

        <label htmlFor="Ciudad-certificado">Ciudad</label>
        <input
          type="text"
          id="Ciudad-certificado"
          placeholder="Ingrese Ciudad"
          name="Ciudadcertificado"
          value={ Ciudadcertificado }
          onChange={handleInputChange}
        />

        <label htmlFor="Tipo-calificacion">Tipo calificacion</label>

        <span>
        <label htmlFor="Operacional">Operacional</label>
        <input
          type="radio"
          id="Operacional"
          placeholder="Ingrese si es Operacional"
          name="TypeCalification"
          value='Operacional'
          onChange={handleInputChange}
        />
        </span>

        <label htmlFor="Desempeño">Desempeño</label>
        <input
          type="radio"
          id="Desempeño"
          placeholder="Ingrese si es de Desempeño"
          name="TypeCalification"
          value='Desempeño'
          onChange={handleInputChange}
        />

      </form>
      <button type="submit" onClick={ handleClick }>enviar</button>
    </div>
  );
}

