import './CertificadoOp1.scss';
import React from 'react';


export default function CertificadoOp1 () {
    return(
        <section className="container-certOP">
        <div className="container-title-certOP">
            <h3>Informe De Calificación Operacional </h3>
            <form
            className="form-certop"
            name="formularioCertOP"
            method="get"
            action="/"
          >
           <input
              className="numeroinforme"
              type="text"
              id="NumeroInforme"
              placeholder="Informe No 00000000"
              name="NumeroInforme"
            />
            </form>
        </div>
        <form
            className="form-clienteop"
            name="formularioclienteOP"
            method="get"
            action="/"
          >
            <label htmlFor="ClienteOp">Cliente</label>
            <input
              className="clienteop"
              type="text"
              id="ClienteOp"
              placeholder="Ingrese Cliente"
              name="ClienteOP"
            />
            </form>

            <form
            className="form-equipoOp"
            name="formularioclienteOP"
            method="get"
            action="/"
          >
            <label htmlFor="EquipoOp">Equipo</label>
            <input
              className="equipoOp"
              type="text"
              id="EquipoOp"
              placeholder="Ingrese Equipo"
              name="EquipoOP"
            />

            <label htmlFor="MarcaOp">Marca</label>
            <input
              className="marcaop"
              type="text"
              id="MarcaOp"
              placeholder="Ingrese Marca"
              name="MarcaOP"
            />
            
            <label htmlFor="ModeloOp">Modelo</label>
            <input
              className="modeloOp"
              type="text"
              id="ModeloOp"
              placeholder="Ingrese Modelo"
              name="ModeloOP"
            />
            
            <label htmlFor="SerieOp">Serie</label>
            <input
              className="serieop"
              type="text"
              id="SerieOp"
              placeholder="Ingrese Serie"
              name="SerieOP"
            />
            
            <label htmlFor="NoInventarioOp">No Inventario</label>
            <input
              className="noinventarioOp"
              type="text"
              id="NoInventarioOp"
              placeholder="Ingrese No Inventario"
              name="NoInventarioOP"
            />
            
            <label htmlFor="OtroOp">Otro</label>
            <input
              className="otroOp"
              type="text"
              id="OtroOp"
              placeholder="Ingrese Otro"
              name="OrtoOP"
            />
            
            <label htmlFor="InvAreaOp">Inventario Area</label>
            <input
              className="invareaop"
              type="text"
              id="InvAreaOp"
              placeholder="Ingrese Inventario Area"
              name="InvAreaOP"
            />
            
            <label htmlFor="PordAlmOp">Producto Almacenado</label>
            <input
              className="prodalmop"
              type="text"
              id="ProdAlmOp"
              placeholder="Ingrese Producto Almacenado"
              name="ProdAlmOP"
            />
            
            <label htmlFor="DireccionOp">Direccion</label>
            <input
              className="direccionop"
              type="text"
              id="DireccionOp"
              placeholder="Ingrese Direccion"
              name="DireccionOP"
            />
            
            <label htmlFor="CiudadOp">Ciudad</label>
            <input
              className="ciudadop"
              type="text"
              id="CiudadOp"
              placeholder="Ingrese Ciudad"
              name="CiudadOP"
            />
            
            <label htmlFor="ContactoOp">Contacto</label>
            <input
              className="contactoop"
              type="text"
              id="ContactoOp"
              placeholder="Ingrese Contacto"
              name="ContactoOP"
            />
            
            <label htmlFor="CargoOp">Cargo</label>
            <input
              className="cargoOp"
              type="text"
              id="CargoOp"
              placeholder="Ingrese Cargo"
              name="CargoOP"
            />
            
            <label htmlFor="FechaCalfOp">Fecha Calificacion</label>
            <input
              className="fechacalop"
              type="text"
              id="FechaCalOp"
              placeholder="Ingrese Fecha Calificacion"
              name="FechaCalOP"
            />
            
            <label htmlFor="FechaRCalOp">Fecha Recalificacion</label>
            <input
              className="fecharcalop"
              type="text"
              id="FechaRCalOp"
              placeholder="Ingrese Fecha Recalificacion"
              name="FechaRCalOP"
            />
            
            </form>

            <h4>Aprobacion</h4>
            <div className="containeraprovacion">
            <form
            className="form-nombreop"
            name="formularioCertOP"
            method="get"
            action="/"
          >
          <label htmlFor="NombreOp">Nombre</label>
           <input
              className="nombreop"
              type="text"
              id="NombreOp"
              placeholder=""
              name="NombreOp"
            />
            <input
              className="nombreop"
              type="text"
              id="NombreOp"
              placeholder=""
              name="NombreOp"
            />
            <input
              className="nombreop"
              type="text"
              id="NombreOp"
              placeholder=""
              name="NombreOp"
            />
            </form>

            <form
            className="form-cargoop"
            name="formularioCertOP"
            method="get"
            action="/"
          >
          <label htmlFor="CargoOp">Cargo</label>
           <input
              className="cargoOp"
              type="text"
              id="CargoOp"
              placeholder=""
              name="CargoOp"
            />
            <input
              className="cargoOp"
              type="text"
              id="CargoOp"
              placeholder=""
              name="CargoOp"
            />
            <input
              className="cargoOp"
              type="text"
              id="CargoOp"
              placeholder=""
              name="CargoOp"
            />
            
            </form>

            <form
            className="form-firmaop"
            name="formularioCertOP"
            method="get"
            action="/"
          >
          <label htmlFor="FirmaOp">Firma</label>
           <input
              className="firmaop"
              type="file"
              id="FirmaOp"
              placeholder=""
              name="FirmaOp"
            />
            <input
              className="firmaop"
              type="file"
              id="FirmaOp"
              placeholder=""
              name="FirmaOp"
            />
            <input
              className="firmaop"
              type="file"
              id="FirmaOp"
              placeholder=""
              name="FirmaOp"
            />
            </form>

            <form
            className="form-fechaop"
            name="formularioCertOP"
            method="get"
            action="/"
          >
          <label htmlFor="FechaOp">Fecha</label>
           <input
              className="fechaop"
              type="text"
              id="FechaOp"
              placeholder=""
              name="FechaOp"
            />
            <input
              className="fechaop"
              type="text"
              id="FechaOp"
              placeholder=""
              name="FechaOp"
            />
            <input
              className="fechaop"
              type="text"
              id="FechaOp"
              placeholder=""
              name="FechaOp"
            />
            </form>
            </div>
        </section>
    )
}