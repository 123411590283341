import "./CertificadoOp8.scss";
import pos1 from '../../../assets/imgs/pos1.png'
import pos2 from '../../../assets/imgs/pos2.png'
import pos3 from '../../../assets/imgs/pos3.png'
import pos4 from '../../../assets/imgs/pos5.png'
import React from "react";

export const CertificadoOp8 = () => {
  return (
    <div className="container6">
      <dl>
        <dt>6. RESULTADOS DE LAS PRUEBAS</dt>
        <dd>
          Las pruebas se realizaron en Laboratorio de Calidad de Biored
          Ingeniería Ltda., los resultados se refrieren a condiciones, lugar y
          fecha de realización de la prueba
          <dt>6.1. Verificación de uso previsto:</dt>
          <dd>
            <dt>Criterio(s) de aceptación: Fabricante</dt>
            <dd>
              El equipo debe ser adecuado para almacenar y conservar productos
              que requieran refrigeración en un rango general de 1°C a 10°C.
            </dd>
            <dt>Resultados: </dt>
            <dd>
              El equipo evaluado es un refrigerador para uso específico de
              almacenamiento y conservación de hemocomponentes, reactivos,
              fármacos, y otros productos médicos y científicos, con método de
              refrigeración de circulación de aire frío desde la parte superior
              en donde se encuentra ubicado el evaporador, circulando hacia la
              parte inferior en un flujo continuo que permite el enfriamiento de
              la cámara. ESTO ES FIJO
            </dd>
            <dd>
              De acuerdo a lo verificado en los documentos técnicos, las
              condiciones mínimas recomendadas por el fabricante para su
              operación son entre 15°C y 32°C de temperatura ambiente y 80%
              máximo de humedad relativa.*
            </dd>
            <dd>
              El equipo está diseñado para conservar temperaturas de
              refrigeración en un rango general entre 1°C a 10°C, con una
              uniformidad al interior de la cámara de ± 1,5°C, cuenta con
              control digital de temperatura que realiza el control del sistema
              de refrigeración y están programadas para que el equipo trabaje en
              el rango de 3°C a 5°C.
            </dd>
          </dd>
          <dt>6.2. Calificación de la distribución de temperatura</dt>
          <dd>
            <dt>Criterio(s) de aceptación: Fabricante</dt>
            <dd>
              La temperatura promedio en el interior de la cámara durante cada
              periodo estable evaluado debe estar entre 1°C y 10°C para
              conservar los productos almacenados en óptimas condiciones.
            </dd>
            <dt>Resultados: </dt>
            <dd>
              Descripción de la carga: Al momento de realizar la calificación
              operacional, el equipo se encuentra funcionando y sin carga.
              <dd>
                Fecha y hora de inicio de la calificación: lunes, 05 de Abril de
                2021 - 4:50 pm
              </dd>
              <dd>
                Fecha y hora de finalización de la calificación: martes, 06 de
                Abril de 2021 - 5:00 pm
              </dd>
              <dd>Temperatura programada: 3°C a 5°C</dd>
            </dd>
          </dd>
        </dd>
      </dl>
      <div className="container61">
            <dl>
                <dt></dt>
                <dd>
                    <dt></dt>
                    <dd>
                        <dt>Temperatura esperada:  1°C a 10°C</dt>
                        <span className="containerimgs6">
                        <dd>
                            <img src={pos1} alt="posicion 1" />
                            <label htmlFor="Pos1">Posicion 1</label>
                            <input type="radio" name="Pos1" id="Pos1" />
                        </dd>
                        <dd>
                            <img src={pos2} alt="posicion 2" />
                            <label htmlFor="Pos1">Posicion 2</label>
                            <input type="radio" name="Pos1" id="Pos1" />
                        </dd>
                        <dd>
                            <img src={pos3} alt="posicion 3" />
                            <label htmlFor="Pos1">Posicion 3</label>
                            <input type="radio" name="Pos1" id="Pos1" />
                        </dd>
                        <dd>
                            <img src={pos4} alt="posicion 4" />
                            <label htmlFor="Pos1">Posicion 4</label>
                            <input type="radio" name="Pos1" id="Pos1" />
                        </dd>
                        </span>
                    </dd>
                </dd>
            </dl>
        </div>
    </div>
  );
};
