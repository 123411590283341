import "./CertificadoOp11.scss";
import React from "react";

export const CertificadoOp11 = () => {
  return (
    <div className="container64">
      <dl>
        <dd>
          <dt>Sensor 12. + #serie</dt>
          <dd>
            <form className="sensor" name="formularisensor1">
              <span>
                <label htmlFor="Sensor12">Periodo</label>
                <label htmlFor="FechaSensor12">1</label>
                <label htmlFor="FechaSensor12">2</label>
                <label htmlFor="FechaSensor12">3</label>
              </span>
              <span>
                <label htmlFor="FechaSensor12">Fecha de Inicio</label>
                <input
                  className="FechaSensor12"
                  type="text"
                  id="FechaSensor12"
                  placeholder="01-Ene-2021"
                  name="FechaSensor12"
                />
                <input
                  className="FechaSensor12"
                  type="text"
                  id="FechaSensor12"
                  placeholder="01-Ene-2021"
                  name="FechaSensor12"
                />
                <input
                  className="FechaSensor12"
                  type="text"
                  id="FechaSensor12"
                  placeholder="01-Ene-2021"
                  name="FechaSensor12"
                />
              </span>
              <span>
                <label htmlFor="HoraSensor12">Hora de Inicio</label>
                <input
                  className="HoraSensor12"
                  type="text"
                  id="HoraSensor12"
                  placeholder="00:00:00"
                  name="HoraSensor12"
                />
                <input
                  className="HoraSensor12"
                  type="text"
                  id="HoraSensor12"
                  placeholder="00:00:00"
                  name="HoraSensor12"
                />
                <input
                  className="HoraSensor12"
                  type="text"
                  id="HoraSensor12"
                  placeholder="00:00:00"
                  name="HoraSensor12"
                />
              </span>
              <span>
                <label htmlFor="FechaFSensor12">Fecha de Final</label>
                <input
                  className="FechaFSensor12"
                  type="text"
                  id="FechaFSensor12"
                  placeholder="01-Ene-2021"
                  name="FechaFSensor12"
                />
                <input
                  className="FechaFSensor12"
                  type="text"
                  id="FechaFSensor12"
                  placeholder="01-Ene-2021"
                  name="FechaFSensor12"
                />
                <input
                  className="FechaFSensor12"
                  type="text"
                  id="FechaFSensor12"
                  placeholder="01-Ene-2021"
                  name="FechaFSensor12"
                />
              </span>
              <span>
                <label htmlFor="HoraFSensor12">Hora de Final</label>
                <input
                  className="HoraFSensor12"
                  type="text"
                  id="HoraFSensor12"
                  placeholder="00:00:00"
                  name="HoraFSensor12"
                />
                <input
                  className="HoraFSensor12"
                  type="text"
                  id="HoraFSensor12"
                  placeholder="00:00:00"
                  name="HoraFSensor12"
                />
                <input
                  className="HoraFSensor12"
                  type="text"
                  id="HoraFSensor12"
                  placeholder="00:00:00"
                  name="HoraFSensor12"
                />
              </span>
              <span>
                <label htmlFor="TemMaxSensor12">Temp. Máx. </label>
                <input
                  className="TemMaxSensor12"
                  type="text"
                  id="TemMaxSensor12"
                  placeholder="4,0"
                  name="TemMaxSensor12"
                />
                <input
                  className="TemMaxSensor12"
                  type="text"
                  id="TemMaxSensor12"
                  placeholder="4,0"
                  name="TemMaxSensor12"
                />
                <input
                  className="TemMaxSensor12"
                  type="text"
                  id="TemMaxSensor12"
                  placeholder="4,0"
                  name="TemMaxSensor12"
                />
              </span>
              <span>
                <label htmlFor="TemMinSensor12">Temp. Min. </label>
                <input
                  className="TemMinSensor12"
                  type="text"
                  id="TemMinSensor12"
                  placeholder="2,0"
                  name="TemMinSensor12"
                />
                <input
                  className="TemMinSensor12"
                  type="text"
                  id="TemMinSensor12"
                  placeholder="2,0"
                  name="TemMinSensor12"
                />
                <input
                  className="TemMinSensor12"
                  type="text"
                  id="TemMinSensor12"
                  placeholder="2,0"
                  name="TemMinSensor12"
                />
              </span>
              <span>
                <label htmlFor="TemPromSensor12">Temp. Promedio </label>
                <input
                  className="TemPromSensor12"
                  type="text"
                  id="TemPromSensor12"
                  placeholder="2,9"
                  name="TemPromSensor12"
                />
                <input
                  className="TemPromSensor12"
                  type="text"
                  id="TemPromSensor12"
                  placeholder="2,9"
                  name="TemPromSensor12"
                />
                <input
                  className="TemPromSensor12"
                  type="text"
                  id="TemPromSensor12"
                  placeholder="2,9"
                  name="TemPromSensor12"
                />
              </span>
              <span>
                <label htmlFor="DevEstSensor12">Desv. Estandar </label>
                <input
                  className="DevEstSensor12"
                  type="text"
                  id="DevEstSensor12"
                  placeholder="0,6"
                  name="DevEstSensor12"
                />
                <input
                  className="DevEstSensor12"
                  type="text"
                  id="DevEstSensor12"
                  placeholder="0,6"
                  name="DevEstSensor12"
                />
                <input
                  className="DevEstSensor12"
                  type="text"
                  id="DevEstSensor12"
                  placeholder="0,6"
                  name="DevEstSensor12"
                />
              </span>
              <span className="cumpleSensor">
                <label htmlFor="CumpleSensor12">Cumple</label>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
              </span>
            </form>
          </dd>
          <dt>Sensor 13. + #serie Temperatura & Humedad Ambiente </dt>
          <dd>
            <form className="sensor" name="formularisensor1">
              <span>
                <label htmlFor="Sensor13">Periodo</label>
                <label htmlFor="FechaSensor13">1</label>
                <label htmlFor="FechaSensor13">2</label>
                <label htmlFor="FechaSensor13">3</label>
              </span>
              <span>
                <label htmlFor="FechaSensor13">Fecha de Inicio</label>
                <input
                  className="FechaSensor13"
                  type="text"
                  id="FechaSensor13"
                  placeholder="01-Ene-2021"
                  name="FechaSensor13"
                />
                <input
                  className="FechaSensor13"
                  type="text"
                  id="FechaSensor13"
                  placeholder="01-Ene-2021"
                  name="FechaSensor13"
                />
                <input
                  className="FechaSensor13"
                  type="text"
                  id="FechaSensor13"
                  placeholder="01-Ene-2021"
                  name="FechaSensor13"
                />
              </span>
              <span>
                <label htmlFor="HoraSensor13">Hora de Inicio</label>
                <input
                  className="HoraSensor13"
                  type="text"
                  id="HoraSensor13"
                  placeholder="00:00:00"
                  name="HoraSensor13"
                />
                <input
                  className="HoraSensor13"
                  type="text"
                  id="HoraSensor13"
                  placeholder="00:00:00"
                  name="HoraSensor13"
                />
                <input
                  className="HoraSensor13"
                  type="text"
                  id="HoraSensor13"
                  placeholder="00:00:00"
                  name="HoraSensor13"
                />
              </span>
              <span>
                <label htmlFor="FechaFSensor13">Fecha de Final</label>
                <input
                  className="FechaFSensor13"
                  type="text"
                  id="FechaFSensor13"
                  placeholder="01-Ene-2021"
                  name="FechaFSensor13"
                />
                <input
                  className="FechaFSensor13"
                  type="text"
                  id="FechaFSensor13"
                  placeholder="01-Ene-2021"
                  name="FechaFSensor13"
                />
                <input
                  className="FechaFSensor13"
                  type="text"
                  id="FechaFSensor13"
                  placeholder="01-Ene-2021"
                  name="FechaFSensor13"
                />
              </span>
              <span>
                <label htmlFor="HoraFSensor13">Hora de Final</label>
                <input
                  className="HoraFSensor13"
                  type="text"
                  id="HoraFSensor13"
                  placeholder="00:00:00"
                  name="HoraFSensor13"
                />
                <input
                  className="HoraFSensor13"
                  type="text"
                  id="HoraFSensor13"
                  placeholder="00:00:00"
                  name="HoraFSensor13"
                />
                <input
                  className="HoraFSensor13"
                  type="text"
                  id="HoraFSensor13"
                  placeholder="00:00:00"
                  name="HoraFSensor13"
                />
              </span>
              <span>
                <label htmlFor="HumMaxSensor13">Humedad Max. </label>
                <input
                  className="HumMaxSensor13"
                  type="text"
                  id="HumMaxSensor13"
                  placeholder="4,0"
                  name="HumMaxSensor13"
                />
                <input
                  className="HumMaxSensor13"
                  type="text"
                  id="HumMaxSensor13"
                  placeholder="4,0"
                  name="HumMaxSensor13"
                />
                <input
                  className="HumMaxSensor13"
                  type="text"
                  id="HumMaxSensor13"
                  placeholder="4,0"
                  name="HumMaxSensor13"
                />
              </span>
              <span>
                <label htmlFor="HumMinSensor13">Humedad Min. </label>
                <input
                  className="HumMinSensor13"
                  type="text"
                  id="HumMinSensor13"
                  placeholder="2,0"
                  name="HumMinSensor13"
                />
                <input
                  className="HumMinSensor13"
                  type="text"
                  id="HumMinSensor13"
                  placeholder="2,0"
                  name="HumMinSensor13"
                />
                <input
                  className="HumMinSensor13"
                  type="text"
                  id="HumMinSensor13"
                  placeholder="2,0"
                  name="HumMinSensor13"
                />
              </span>
              <span>
                <label htmlFor="HumPromSensor13">Humedad Promedio </label>
                <input
                  className="HumPromSensor13"
                  type="text"
                  id="HumPromSensor13"
                  placeholder="2,9"
                  name="HumPromSensor13"
                />
                <input
                  className="HumPromSensor13"
                  type="text"
                  id="HumPromSensor13"
                  placeholder="2,9"
                  name="HumPromSensor13"
                />
                <input
                  className="HumPromSensor13"
                  type="text"
                  id="HumPromSensor13"
                  placeholder="2,9"
                  name="HumPromSensor13"
                />
              </span>
              <span>
                <label htmlFor="DevEstSensor13">Desv. Estandar </label>
                <input
                  className="DevEstSensor13"
                  type="text"
                  id="DevEstSensor13"
                  placeholder="0,1"
                  name="DevEstSensor13"
                />
                <input
                  className="DevEstSensor13"
                  type="text"
                  id="DevEstSensor13"
                  placeholder="0,1"
                  name="DevEstSensor13"
                />
                <input
                  className="DevEstSensor13"
                  type="text"
                  id="DevEstSensor13"
                  placeholder="0,1"
                  name="DevEstSensor13"
                />
              </span>
              <span className="cumpleSensor">
                <label htmlFor="CumpleSensor13">Cumple</label>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
              </span>
            </form>
          </dd>
          <dt> </dt>
          <dd>
            <form className="sensor" name="formularisensor1">
              <span>
                <label htmlFor="Sensor13">Periodo</label>
                <label htmlFor="FechaSensor13">1</label>
                <label htmlFor="FechaSensor13">2</label>
                <label htmlFor="FechaSensor13">3</label>
              </span>
              <span>
                <label htmlFor="FechaSensor13">Fecha de Inicio</label>
                <input
                  className="FechaSensor13"
                  type="text"
                  id="FechaSensor13"
                  placeholder="01-Ene-2021"
                  name="FechaSensor13"
                />
                <input
                  className="FechaSensor13"
                  type="text"
                  id="FechaSensor13"
                  placeholder="01-Ene-2021"
                  name="FechaSensor13"
                />
                <input
                  className="FechaSensor13"
                  type="text"
                  id="FechaSensor13"
                  placeholder="01-Ene-2021"
                  name="FechaSensor13"
                />
              </span>
              <span>
                <label htmlFor="HoraSensor13">Hora de Inicio</label>
                <input
                  className="HoraSensor13"
                  type="text"
                  id="HoraSensor13"
                  placeholder="00:00:00"
                  name="HoraSensor13"
                />
                <input
                  className="HoraSensor13"
                  type="text"
                  id="HoraSensor13"
                  placeholder="00:00:00"
                  name="HoraSensor13"
                />
                <input
                  className="HoraSensor13"
                  type="text"
                  id="HoraSensor13"
                  placeholder="00:00:00"
                  name="HoraSensor13"
                />
              </span>
              <span>
                <label htmlFor="FechaFSensor13">Fecha de Final</label>
                <input
                  className="FechaFSensor13"
                  type="text"
                  id="FechaFSensor13"
                  placeholder="01-Ene-2021"
                  name="FechaFSensor13"
                />
                <input
                  className="FechaFSensor13"
                  type="text"
                  id="FechaFSensor13"
                  placeholder="01-Ene-2021"
                  name="FechaFSensor13"
                />
                <input
                  className="FechaFSensor13"
                  type="text"
                  id="FechaFSensor13"
                  placeholder="01-Ene-2021"
                  name="FechaFSensor13"
                />
              </span>
              <span>
                <label htmlFor="HoraFSensor13">Hora de Final</label>
                <input
                  className="HoraFSensor13"
                  type="text"
                  id="HoraFSensor13"
                  placeholder="00:00:00"
                  name="HoraFSensor13"
                />
                <input
                  className="HoraFSensor13"
                  type="text"
                  id="HoraFSensor13"
                  placeholder="00:00:00"
                  name="HoraFSensor13"
                />
                <input
                  className="HoraFSensor13"
                  type="text"
                  id="HoraFSensor13"
                  placeholder="00:00:00"
                  name="HoraFSensor13"
                />
              </span>
              <span>
                <label htmlFor="TemMaxSensor13">Temp. Máx. </label>
                <input
                  className="TemMaxSensor13"
                  type="text"
                  id="TemMaxSensor13"
                  placeholder="4,0"
                  name="TemMaxSensor13"
                />
                <input
                  className="TemMaxSensor13"
                  type="text"
                  id="TemMaxSensor13"
                  placeholder="4,0"
                  name="TemMaxSensor13"
                />
                <input
                  className="TemMaxSensor13"
                  type="text"
                  id="TemMaxSensor13"
                  placeholder="4,0"
                  name="TemMaxSensor13"
                />
              </span>
              <span>
                <label htmlFor="TemMinSensor13">Temp. Min. </label>
                <input
                  className="TemMinSensor13"
                  type="text"
                  id="TemMinSensor13"
                  placeholder="2,0"
                  name="TemMinSensor13"
                />
                <input
                  className="TemMinSensor13"
                  type="text"
                  id="TemMinSensor13"
                  placeholder="2,0"
                  name="TemMinSensor13"
                />
                <input
                  className="TemMinSensor13"
                  type="text"
                  id="TemMinSensor13"
                  placeholder="2,0"
                  name="TemMinSensor13"
                />
              </span>
              <span>
                <label htmlFor="TemPromSensor13">Temp. Promedio </label>
                <input
                  className="TemPromSensor13"
                  type="text"
                  id="TemPromSensor13"
                  placeholder="2,9"
                  name="TemPromSensor13"
                />
                <input
                  className="TemPromSensor13"
                  type="text"
                  id="TemPromSensor13"
                  placeholder="2,9"
                  name="TemPromSensor13"
                />
                <input
                  className="TemPromSensor13"
                  type="text"
                  id="TemPromSensor13"
                  placeholder="2,9"
                  name="TemPromSensor13"
                />
              </span>
              <span>
                <label htmlFor="DevEstSensor13">Desv. Estandar </label>
                <input
                  className="DevEstSensor13"
                  type="text"
                  id="DevEstSensor13"
                  placeholder="0,6"
                  name="DevEstSensor13"
                />
                <input
                  className="DevEstSensor13"
                  type="text"
                  id="DevEstSensor13"
                  placeholder="0,6"
                  name="DevEstSensor13"
                />
                <input
                  className="DevEstSensor13"
                  type="text"
                  id="DevEstSensor13"
                  placeholder="0,6"
                  name="DevEstSensor13"
                />
              </span>
              <span className="cumpleSensor">
                <label htmlFor="CumpleSensor13">Cumple</label>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
              </span>
            </form>
          </dd>
          <dt> </dt>
          
        </dd>
      </dl>
    </div>
  );
};
