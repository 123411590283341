import './CertificadoOp2.scss';
import React from 'react';



export default function CertificadoOp2 () {
    return(
        <section className="container-certOP">
            <div className="container-pruebas">
                <h3>Pruebas Desarrolladas Por:</h3>
                <input
              className="pruebas-desarroladas"
              type="text"
              id="PruebasDesarroladas"
              placeholder="Biored Ingenieria ltda."
              name="PruebasDesarroladas"
            />
            </div>
            <form
            className="form-Pruebasop"
            name="formulariopruebasOP"
            method="get"
            action="/"
          >
            <label htmlFor="DireccionOp">Direccion</label>
            <input
              className="direccionop"
              type="text"
              id="DireccionOp"
              placeholder="Ingrese Direccion"
              name="DireccionOP"
            />
            <label htmlFor="CiudadOp">Ciudad</label>
            <input
              className="ciudadop"
              type="text"
              id="CiudadOp"
              placeholder="Ingrese Ciudad"
              name="CiudadOP"
            />
            <label htmlFor="TelefonoOp">Telefono</label>
            <input
              className="telefonoop"
              type="text"
              id="TelefonoOp"
              placeholder="Ingrese Telefono"
              name="TelefonoOP"
            />
            </form>
            <form
            className="form-resp-pruebaop"
            name="formularioCertOP"
            method="get"
            action="/"
          >
          <label htmlFor="RespServicio">Responsable del Servicio</label>
           <input
              className="respservicio"
              type="text"
              id="RespServicio"
              placeholder="Nombre del tecnico"
              name="RespServicio"
            />
            <input
              className="respservicio"
              type="file"
              id="RespServicio"
              placeholder=""
              name="RespServicio"
            />
             <label htmlFor="RespInforme">Responsable del Informe</label>
           <input
              className="respinforme"
              type="text"
              id="RespInforme"
              placeholder="Nombre del Ingeniero"
              name="RespInforme"
            />
            <input
              className="respinforme"
              type="file"
              id="RespInforme"
              placeholder=""
              name="RespInforme"
            />
             <label htmlFor="RespRevision">Revisado por:</label>
           <input
              className="resprevision"
              type="text"
              id="RespRevision"
              placeholder="Nombre de quien reviso"
              name="RespRevision"
            />
            <input
              className="resprevision"
              type="file"
              id="RespRevision"
              placeholder=""
              name="RespRevision"
            />
            </form>
        </section>
    )
}