import './Nav.scss'
import React from 'react';

export default function NavRed (props) {
    return (
        <div className="container-nav-red">
            <span className="container-info">
                <h3>{ props.tittle }</h3>
            </span>
        </div>
    )
}