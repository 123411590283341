import "./CertificadoOp6.scss";
import React from "react";

export const CertificadoOP6 = () => {
  return (
    <div className="container5">
      <dl>
        <dt>5. DESCRIPCIÓN DE LAS PRUEBAS</dt>
        <dd>
          Para realizar la calificación de operación del equipo, es necesario
          evaluar su funcionamiento durante 24 horas continuas, con el objetivo
          de demostrar que el equipo opera dentro del rango de temperatura
          programada, que los sensores y el control funcionan de manera
          apropiada y que responde adecuadamente durante pruebas de fallas de
          energía, simulación de alarmas; realizando las siguientes pruebas:
          <dl>
            <dt>5.1. Criterios de aceptación:</dt>
            <dd>
              Los criterios de aceptación son establecidos por el usuario, de
              acuerdo a la expectativa que tenga del uso del equipo en
              evaluación, al producto que almacena, a protocolos internos de la
              Institución y siempre y cuando no excedan las posibilidades
              técnicas del equipo. Para algunas pruebas, el criterio de
              aceptación se establece con base en la respuesta esperada del
              equipo según la tecnología de fabricación del mismo
            </dd>
            <dt>5.2. Verificación de uso previsto:</dt>
            <dd>
              Se realiza una verificación de las características técnicas del
              equipo, con base en las fichas técnicas y manuales, con el
              propósito de determinar que es adecuado para el uso previsto.
            </dd>
            <dt>5.3. Calificación de la distribución de temperatura:</dt>
            <dd>
              El objetivo de la prueba es comparar los valores de la temperatura
              al interior de la cámara del equipo, contra las especificaciones o
              criterios de aceptación establecidos para el almacenamiento de los
              productos.
            </dd>
            <dd>
              Se ubican los sensores en distintos puntos de la cámara,
              programando las lecturas de las temperaturas cada 2 minutos.
            </dd>
            <dd>
              Se diseña un gráfico que indica la ubicación del sensor(es) de
              control del equipo y la posición de los sensores de evaluación en
              el interior de la cámara.
            </dd>
            <dd>
              Se anexan tablas en las que se consigna el número de sensores
              utilizados, números de serie, número de posición de los sensores,
              temperaturas programadas y esperadas y el criterio de aceptación
              contra el cual se comparan los resultados.
            </dd>
            <dd>
              Se anexan los datos arrojados en las lecturas, cálculos
              estadísticos y gráficos de los registros de temperatura en medio
              magnético.
            </dd>
            <dt>
              5.4. Determinación puntual de temperatura (puntos calientes y
              fríos)
            </dt>
            <dd>
              A partir de los datos medidos con los sensores de evaluación
              ubicados en el equipo, se determinan los puntos de mayor y menor
              temperatura al interior de la cámara, con el objetivo de
              establecer la óptima distribución del producto dentro del equipo.
            </dd>
            <dt>5.5 Pruebas de Fallas de Energía (Hold Over):</dt>
            <dd>
              El objetivo de la prueba es determinar durante cuánto tiempo se
              conservan las condiciones de temperatura programadas al interior
              del equipo cuando se presentan eventualidades como cortes de
              energía, desconexión del equipo, etc.
            </dd>
            <dd>
              Se simula un corte de energía desconectando el equipo de su fuente
              de alimentación con el equipo cerrado. Se determina cuánto tiempo
              le toma al equipo recuperar sus condiciones de temperatura una vez
              restablecido el suministro de energía.
            </dd>
            <dd>
              Se anexa una tabla en la que se registra la información de las
              pruebas de hold over: Fecha, hora de corte, hora de arranque,
              temperatura máxima obtenida, temperatura mínima obtenida, tiempo
              de recuperación de las condiciones de temperatura.
            </dd>

          </dl>
        </dd>
      </dl>
    </div>
  );
};
