import "./PruebaDesempeño.scss";
import React from "react";
import { Link } from "react-router-dom";

export default function PruebaDesempeño() {
  return (
    <div>
      <div className="container-tittles">
        <h4>PROCEDIMIENTO DE SIMULACIÓN DE CARGA </h4>
        <h4>
          Escriba la fecha, hora y temperatura del display del equipo y la hora
          previamente, enseguida abra la puerta y espere un tiempo aproximado de
          dos minutos, escriba nuevamente la temperatura y espere que el equipo
          se estabilice, escriba la hora en que se estabilizo nuevamente el
          equipo.
        </h4>
        <h4>PROCEDIMIENTO REAL CARGA </h4>
        <h4>
          Escriba la fecha, hora, y temperatura del display del equipo,
          enseguida tome la temperatura del producto que van a ingresar tan
          pronto ingrese el producto tome la hora final y la temperatura del
          equipo, espere que el equipo se estabilice, escriba la hora en que se
          estabilizo nuevamente el equipo.
        </h4>
      </div>
      <div className="container-formdesempeño">
        <div>
          <h3>SIMULACIÓN DE CARGA 1</h3>
          <form
            className="form-pruebadesempeño"
            name="formulario"
            method="post"
            action="/"
          >
            <label htmlFor="FechaD">Fecha</label>
            <input
              className="fechad"
              type="text"
              id="FechaD"
              placeholder="Ingrese Fecha"
              name="FechaD"
            />

            <label htmlFor="HoraD">Hora de apertura puerta</label>
            <input
              className="horaD"
              type="text"
              id="HoraD"
              placeholder="Ingrese Hora Apertura"
              name="HoraD"
            />

            <label htmlFor="TempInicio">Temp. Inicio</label>
            <input
              className="tempinicio"
              type="text"
              id="TempInicio"
              placeholder="Ingrese Temp de inicio"
              name="TempInicio"
            />

            <label htmlFor="TempFinal">Temp. Final</label>
            <input
              className="tempfinal"
              type="text"
              id="TempFinal"
              placeholder="Ingrese Temp final"
              name="TempFinal"
            />

            <label htmlFor="TiempoRec">Tiempo de recuperacion</label>
            <input
              className="tiemporec"
              type="text"
              id="TiempoRec"
              placeholder="Ingrese Tiempo recuperacion"
              name="TiempoRec"
            />
          </form>
        </div>

        <div>
          <h3>SIMULACIÓN DE CARGA 2</h3>
          <form
            className="form-pruebadesempeño"
            name="formulario"
            method="post"
            action="/"
          >
            <label htmlFor="FechaD">Fecha</label>
            <input
              className="fechad"
              type="text"
              id="FechaD"
              placeholder="Ingrese Fecha"
              name="FechaD"
            />

            <label htmlFor="HoraD">Hora de apertura puerta</label>
            <input
              className="horaD"
              type="text"
              id="HoraD"
              placeholder="Ingrese Hora Apertura"
              name="HoraD"
            />

            <label htmlFor="TempInicio">Temp. Inicio</label>
            <input
              className="tempinicio"
              type="text"
              id="TempInicio"
              placeholder="Ingrese Temp de inicio"
              name="TempInicio"
            />

            <label htmlFor="TempFinal">Temp. Final</label>
            <input
              className="tempfinal"
              type="text"
              id="TempFinal"
              placeholder="Ingrese Temp final"
              name="TempFinal"
            />

            <label htmlFor="TiempoRec">Tiempo de recuperacion</label>
            <input
              className="tiemporec"
              type="text"
              id="TiempoRec"
              placeholder="Ingrese Tiempo recuperacion"
              name="TiempoRec"
            />
          </form>
        </div>

        <div>
          <h3>SIMULACIÓN DE CARGA 3</h3>
          <form
            className="form-pruebadesempeño"
            name="formulario"
            method="post"
            action="/"
          >
            <label htmlFor="FechaD">Fecha</label>
            <input
              className="fechad"
              type="text"
              id="FechaD"
              placeholder="Ingrese Fecha"
              name="FechaD"
            />

            <label htmlFor="HoraD">Hora de apertura puerta</label>
            <input
              className="horaD"
              type="text"
              id="HoraD"
              placeholder="Ingrese Hora Apertura"
              name="HoraD"
            />

            <label htmlFor="TempInicio">Temp. Inicio</label>
            <input
              className="tempinicio"
              type="text"
              id="TempInicio"
              placeholder="Ingrese Temp de inicio"
              name="TempInicio"
            />

            <label htmlFor="TempFinal">Temp. Final</label>
            <input
              className="tempfinal"
              type="text"
              id="TempFinal"
              placeholder="Ingrese Temp final"
              name="TempFinal"
            />

            <label htmlFor="TiempoRec">Tiempo de recuperacion</label>
            <input
              className="tiemporec"
              type="text"
              id="TiempoRec"
              placeholder="Ingrese Tiempo recuperacion"
              name="TiempoRec"
            />
          </form>
        </div>

        <div>
          <h3>SIMULACIÓN DE CARGA 4</h3>
          <form
            className="form-pruebadesempeño"
            name="formulario"
            method="post"
            action="/"
          >
            <label htmlFor="FechaD">Fecha</label>
            <input
              className="fechad"
              type="text"
              id="FechaD"
              placeholder="Ingrese Fecha"
              name="FechaD"
            />

            <label htmlFor="HoraD">Hora de apertura puerta</label>
            <input
              className="horaD"
              type="text"
              id="HoraD"
              placeholder="Ingrese Hora Apertura"
              name="HoraD"
            />

            <label htmlFor="TempInicio">Temp. Inicio</label>
            <input
              className="tempinicio"
              type="text"
              id="TempInicio"
              placeholder="Ingrese Temp de inicio"
              name="TempInicio"
            />

            <label htmlFor="TempFinal">Temp. Final</label>
            <input
              className="tempfinal"
              type="text"
              id="TempFinal"
              placeholder="Ingrese Temp final"
              name="TempFinal"
            />

            <label htmlFor="TiempoRec">Tiempo de recuperacion</label>
            <input
              className="tiemporec"
              type="text"
              id="TiempoRec"
              placeholder="Ingrese Tiempo recuperacion"
              name="TiempoRec"
            />
          </form>
        </div>
      </div>
      <div>
        <div className="container-RD">
         
         
        <div>
            <h3>SIMULACIÓN DE CARGA REAL 1</h3>
            <form
            className="form-pruebarealdesempeño"
            name="formulario"
            method="post"
            action="/"
          >
            <label htmlFor="FechaRD">Fecha</label>
            <input
              className="fechard"
              type="text"
              id="FechaRD"
              placeholder="Ingrese Fecha"
              name="FechaRD"
            />

            <label htmlFor="HoraRD">Hora de apertura puerta</label>
            <input
              className="horard"
              type="text"
              id="HoraRD"
              placeholder="Ingrese Hora Apertura"
              name="HoraRD"
            />

            <label htmlFor="ProductoRD">Producto</label>
            <input
              className="productord"
              type="text"
              id="ProductoRD"
              placeholder="Ingrese Producto"
              name="ProductoRD"
            />

            <label htmlFor="CantidadRD">Cantidad</label>
            <input
              className="cantidadrd"
              type="text"
              id="CantidadRD"
              placeholder="Ingrese Cantidad"
              name="CantidadRD"
            />

            <label htmlFor="TempProdRD">Temp. del producto</label>
            <input
              className="tempprodrd"
              type="text"
              id="TempProdRD"
              placeholder="Ingrese Temp producto"
              name="TempProdRD"
            />
            
            <label htmlFor="TiempoCargaRD">Tiempo de carga</label>
            <input
              className="tiempocargard"
              type="text"
              id="TiempoCargaRD"
              placeholder="Ingrese Tiempo de carga"
              name="TiempoCargaRD"
            />
            
            <label htmlFor="TempEquipoRD">Temp. Final del equipo</label>
            <input
              className="tempequipord"
              type="text"
              id="TempEquipoRD"
              placeholder="Ingrese Temp equipo"
              name="TempEquipoRD"
            />
            
            <label htmlFor="TimeRecRD">Tiempo de recuperacion temp.</label>
            <input
              className="timerecrd"
              type="text"
              id="TimeRecRD"
              placeholder="Ingrese tiempo recuperacion"
              name="TimeRecRD"
            />
          </form>
          </div>

          <div>
            <h3>SIMULACIÓN DE CARGA REAL 2</h3>
            <form
            className="form-pruebarealdesempeño"
            name="formulario"
            method="post"
            action="/"
          >
            <label htmlFor="FechaRD">Fecha</label>
            <input
              className="fechard"
              type="text"
              id="FechaRD"
              placeholder="Ingrese Fecha"
              name="FechaRD"
            />

            <label htmlFor="HoraRD">Hora de apertura puerta</label>
            <input
              className="horard"
              type="text"
              id="HoraRD"
              placeholder="Ingrese Hora Apertura"
              name="HoraRD"
            />

            <label htmlFor="ProductoRD">Producto</label>
            <input
              className="productord"
              type="text"
              id="ProductoRD"
              placeholder="Ingrese Producto"
              name="ProductoRD"
            />

            <label htmlFor="CantidadRD">Cantidad</label>
            <input
              className="cantidadrd"
              type="text"
              id="CantidadRD"
              placeholder="Ingrese Cantidad"
              name="CantidadRD"
            />

            <label htmlFor="TempProdRD">Temp. del producto</label>
            <input
              className="tempprodrd"
              type="text"
              id="TempProdRD"
              placeholder="Ingrese Temp producto"
              name="TempProdRD"
            />
            
            <label htmlFor="TiempoCargaRD">Tiempo de carga</label>
            <input
              className="tiempocargard"
              type="text"
              id="TiempoCargaRD"
              placeholder="Ingrese Tiempo de carga"
              name="TiempoCargaRD"
            />
            
            <label htmlFor="TempEquipoRD">Temp. Final del equipo</label>
            <input
              className="tempequipord"
              type="text"
              id="TempEquipoRD"
              placeholder="Ingrese Temp equipo"
              name="TempEquipoRD"
            />
            
            <label htmlFor="TimeRecRD">Tiempo de recuperacion temp.</label>
            <input
              className="timerecrd"
              type="text"
              id="TimeRecRD"
              placeholder="Ingrese tiempo recuperacion"
              name="TimeRecRD"
            />
          </form>
          </div>

          <div>
            <h3>SIMULACIÓN DE CARGA REAL 3</h3>
            <form
            className="form-pruebarealdesempeño"
            name="formulario"
            method="post"
            action="/"
          >
            <label htmlFor="FechaRD">Fecha</label>
            <input
              className="fechard"
              type="text"
              id="FechaRD"
              placeholder="Ingrese Fecha"
              name="FechaRD"
            />

            <label htmlFor="HoraRD">Hora de apertura puerta</label>
            <input
              className="horard"
              type="text"
              id="HoraRD"
              placeholder="Ingrese Hora Apertura"
              name="HoraRD"
            />

            <label htmlFor="ProductoRD">Producto</label>
            <input
              className="productord"
              type="text"
              id="ProductoRD"
              placeholder="Ingrese Producto"
              name="ProductoRD"
            />

            <label htmlFor="CantidadRD">Cantidad</label>
            <input
              className="cantidadrd"
              type="text"
              id="CantidadRD"
              placeholder="Ingrese Cantidad"
              name="CantidadRD"
            />

            <label htmlFor="TempProdRD">Temp. del producto</label>
            <input
              className="tempprodrd"
              type="text"
              id="TempProdRD"
              placeholder="Ingrese Temp producto"
              name="TempProdRD"
            />
            
            <label htmlFor="TiempoCargaRD">Tiempo de carga</label>
            <input
              className="tiempocargard"
              type="text"
              id="TiempoCargaRD"
              placeholder="Ingrese Tiempo de carga"
              name="TiempoCargaRD"
            />
            
            <label htmlFor="TempEquipoRD">Temp. Final del equipo</label>
            <input
              className="tempequipord"
              type="text"
              id="TempEquipoRD"
              placeholder="Ingrese Temp equipo"
              name="TempEquipoRD"
            />
            
            <label htmlFor="TimeRecRD">Tiempo de recuperacion temp.</label>
            <input
              className="timerecrd"
              type="text"
              id="TimeRecRD"
              placeholder="Ingrese tiempo recuperacion"
              name="TimeRecRD"
            />
          </form>
          </div>

          <div>
            <h3>SIMULACIÓN DE CARGA REAL 4</h3>
            <form
            className="form-pruebarealdesempeño"
            name="formulario"
            method="post"
            action="/"
          >
            <label htmlFor="FechaRD">Fecha</label>
            <input
              className="fechard"
              type="text"
              id="FechaRD"
              placeholder="Ingrese Fecha"
              name="FechaRD"
            />

            <label htmlFor="HoraRD">Hora de apertura puerta</label>
            <input
              className="horard"
              type="text"
              id="HoraRD"
              placeholder="Ingrese Hora Apertura"
              name="HoraRD"
            />

            <label htmlFor="ProductoRD">Producto</label>
            <input
              className="productord"
              type="text"
              id="ProductoRD"
              placeholder="Ingrese Producto"
              name="ProductoRD"
            />

            <label htmlFor="CantidadRD">Cantidad</label>
            <input
              className="cantidadrd"
              type="text"
              id="CantidadRD"
              placeholder="Ingrese Cantidad"
              name="CantidadRD"
            />

            <label htmlFor="TempProdRD">Temp. del producto</label>
            <input
              className="tempprodrd"
              type="text"
              id="TempProdRD"
              placeholder="Ingrese Temp producto"
              name="TempProdRD"
            />
            
            <label htmlFor="TiempoCargaRD">Tiempo de carga</label>
            <input
              className="tiempocargard"
              type="text"
              id="TiempoCargaRD"
              placeholder="Ingrese Tiempo de carga"
              name="TiempoCargaRD"
            />
            
            <label htmlFor="TempEquipoRD">Temp. Final del equipo</label>
            <input
              className="tempequipord"
              type="text"
              id="TempEquipoRD"
              placeholder="Ingrese Temp equipo"
              name="TempEquipoRD"
            />
            
            <label htmlFor="TimeRecRD">Tiempo de recuperacion temp.</label>
            <input
              className="timerecrd"
              type="text"
              id="TimeRecRD"
              placeholder="Ingrese tiempo recuperacion"
              name="TimeRecRD"
            />
          </form>
          </div>

        </div>
      </div>
      <div className="containerTec">
      <input
              className="observacioncargaprod"
              type="text"
              id="ObservacionCargaProd"
              placeholder="OBSERVACIONES DEL PROCEDIMIENTO DE CARGA DE PRODUCTO"
              name="ObservacionCargaProd"
            />
         <form
            className="form-firma-tec"
            name="formulario"
            method="post"
            action="/"
          >
          <h4>Procesado por:</h4>
            <label htmlFor="NombreTec">Nombre</label>
            <input
              className="nombretec"
              type="text"
              id="NombreTec"
              placeholder="Ingrese Nombre"
              name="NombreTec"
            />
            <label htmlFor="FirmaTec">Firma</label>
            <input type="file" name="FirmaTec" id="FirmaTec" className="firmatec" />
        </form>
        <input
              className="notastec"
              type="text"
              id="NotasTec"
              placeholder="Ingrese Notas"
              name="NotasTec"
            />
      </div>
      <Link to="/Home">
          <button className="boton-final-registro">Enviar Registro</button>
      </Link>
    </div>
  );
}
