import './CertificadoOp12.scss'
import React from 'react'

export const CertificadoOp12 = () => {
    return (
        <div className="container65">
            <dl>
                <dd>
                    <dt></dt>
                </dd>
            </dl>
        </div>
    )
}
