import '../assets/styles/styles.scss';
import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    } from "react-router-dom";
import Home from '../views/homepage/Home';
import PageRegistro1 from '../views/PageRegistro1';
import CertificadoDesempeño from '../views/CertificadoDesempeño';
import Certificados from '../views/homepage/Certificados';
import CertificadoOperacional from '../views/CertificadoOperacional';
import { AuthData } from '../data/AuthData';



export default function App () {
    AuthData("https://calificaciones.bioredingenieria.com/")
    return(
        <div id="App">
        <Router>
            <Switch>
            <Route path="/PageRegistro1">
            <PageRegistro1 />
            </Route>
            <Route path="/Certificados">
            <Certificados />
            </Route>
            <Route path="/CertificadoOP">
            <CertificadoOperacional />
            </Route>
            <Route path="/CertificadoDP">
            <CertificadoDesempeño />
            </Route>
            <Route path="/">
            <Home />
            </Route>
            <Route path="/Home">
            <Home />
            </Route>
            </Switch>
        </Router>
        </div>
    )
}