import './InfoEquipos.scss'
import React from 'react';

export default function InfoEquipos (){
    return(
        <div className="container-registro">
          <form className="form-registro-InfoEquipos" name="formulario" method="post" action="/">
            
            <label htmlFor="Equipo">EQUIPO</label>
            <input className="equipo" type="text" id="Equipo" placeholder="Ingrese Equipo" name="Equipo" />
            
            <label htmlFor="Marca">MARCA</label>
            <input className="marca" type="text" id="Marca" placeholder="Ingrese Marca" name="Marca" />
            
            <label htmlFor="Modelo">MODELO</label>
            <input className="modelo" type="text" id="Modelo" placeholder="Ingrese Modelo" name="Modelo" />
            
            <label htmlFor="Serie">SERIE</label>
            <input className="serie" type="text" id="Serie" placeholder="Serie" name="Serie" />
          
            <label htmlFor="Sistema-nofrost">SISTEMA  NOFROST</label>
            <span>SI
            <input className="sistema-nofrost" type="checkbox" id="Sistema-nofrost" placeholder="SI" name="Sistema-nofrost" />
            NO
            <input className="sistema-nofrost" type="checkbox" id="Sistema-nofrost" placeholder="NO" name="Sistema-nofrost" />
            </span>
            
            <label htmlFor="No-inventario">No. INVENTARIO</label>
            <input className="no-inventario" type="text" id="No-inventario" placeholder="No-inventario" name="No-inventario" />
            
            <label htmlFor="Foto-externa-del-equipo">FOTO EXTERNA DEL EQUIPO</label>
            <input className="foto-externa-del-equipo" type="file" id="Foto-externa-del-equipo" placeholder="Foto-externa-del-equipo" name="Foto-externa-del-equipo" />
            
            <label htmlFor="Foto-interna-del-equipo">FOTO INTERNA DEL EQUIPO</label>
            <input className="foto-interna-del-equipo" type="file" id="Foto-interna-del-equipo" placeholder="Foto-interna-del-equipo" name="Foto-interna-del-equipo" />
            
            <label htmlFor="Capacidad-en-pies">CAPACIDAD EN PIES</label>
            <input className="capacidad-en-pies" type="text" id="Capacidad-en-pies" placeholder="Capacidad-en-pies" name="Capacidad-en-pies" />

            <label htmlFor="Tiempo-de-defrost">TIEMPO DE  DEFROST</label>
            <input className="tiempo-de-defrost" type="text" id="Tiempo-de-defrost" placeholder="Tiempo-de-defrost" name="Tiempo-de-defrost" />
            </form>

            <form className="form-registro-InfoEquipos2" name="formulario" method="post" action="/">
            <label htmlFor="Estado-general">ESTADO GENERAL</label>
            <input className="estado-general" type="text" id="Estado-general" placeholder="Estado-general" name="Estado-general" />

            <label htmlFor="Puerta-y-empaque">PUERTA Y EMPAQUE</label>
            <input className="puerta-y-empaque" type="text" id="Puerta-y-empaque" placeholder="Puerta-y-empaque" name="Puerta-y-empaque" />
            
            <label htmlFor="Exterior">EXTERIOR</label>
            <input className="exterior" type="text" id="Exterior" placeholder="Exterior" name="Exterior" />
            
            <label htmlFor="Interior">INTERIOR</label>
            <input className="interior" type="text" id="Interior" placeholder="Interior" name="Interior" />
            
            <label htmlFor="Equipo-uso-industrial">EQUIPO USO INDUSTRIAL</label>
            <input className="equipo-uso-industrial" type="text" id="Equipo-uso-industrial" placeholder="Equipo-uso-industrial" name="Equipo-uso-industrial" />
            
            <label htmlFor="Equipo-de-uso-domestico">EQUIPO DE USO DOMESTICO</label>
            <input className="equipo-de-uso-domestico" type="text" id="Equipo-de-uso-domestico" placeholder="Equipo-de-uso-domestico" name="Equipo-de-uso-domestico" />
            </form>
            <form className="form-registro-InfoEquipos3" name="formulario" method="post" action="/">
            <label htmlFor="Observaciones">OBSERVACIONES</label>
            <input className="observaciones" type="text" id="Observaciones" placeholder="Observaciones" name="Observaciones" />
            </form>
            <button>enviar</button>
        </div>
    )
}