import "./CertificadoOp10.scss";
import React from "react";

export const CertificadoOp10 = () => {
  return (
    <div className="container63">
      <dl>
        <dd>
          <dt>Sensor 4. + #serie</dt>
          <dd>
            <form className="sensor" name="formularisensor1">
              <span>
                <label htmlFor="Sensor4">Periodo</label>
                <label htmlFor="FechaSensor4">1</label>
                <label htmlFor="FechaSensor4">2</label>
                <label htmlFor="FechaSensor4">3</label>
              </span>
              <span>
                <label htmlFor="FechaSensor4">Fecha de Inicio</label>
                <input
                  className="FechaSensor4"
                  type="text"
                  id="FechaSensor4"
                  placeholder="01-Ene-2021"
                  name="FechaSensor4"
                />
                <input
                  className="FechaSensor4"
                  type="text"
                  id="FechaSensor4"
                  placeholder="01-Ene-2021"
                  name="FechaSensor4"
                />
                <input
                  className="FechaSensor4"
                  type="text"
                  id="FechaSensor4"
                  placeholder="01-Ene-2021"
                  name="FechaSensor4"
                />
              </span>
              <span>
                <label htmlFor="HoraSensor4">Hora de Inicio</label>
                <input
                  className="HoraSensor4"
                  type="text"
                  id="HoraSensor4"
                  placeholder="00:00:00"
                  name="HoraSensor4"
                />
                <input
                  className="HoraSensor4"
                  type="text"
                  id="HoraSensor4"
                  placeholder="00:00:00"
                  name="HoraSensor4"
                />
                <input
                  className="HoraSensor4"
                  type="text"
                  id="HoraSensor4"
                  placeholder="00:00:00"
                  name="HoraSensor4"
                />
              </span>
              <span>
                <label htmlFor="FechaFSensor4">Fecha de Final</label>
                <input
                  className="FechaFSensor4"
                  type="text"
                  id="FechaFSensor4"
                  placeholder="01-Ene-2021"
                  name="FechaFSensor4"
                />
                <input
                  className="FechaFSensor4"
                  type="text"
                  id="FechaFSensor4"
                  placeholder="01-Ene-2021"
                  name="FechaFSensor4"
                />
                <input
                  className="FechaFSensor4"
                  type="text"
                  id="FechaFSensor4"
                  placeholder="01-Ene-2021"
                  name="FechaFSensor4"
                />
              </span>
              <span>
                <label htmlFor="HoraFSensor4">Hora de Final</label>
                <input
                  className="HoraFSensor4"
                  type="text"
                  id="HoraFSensor4"
                  placeholder="00:00:00"
                  name="HoraFSensor4"
                />
                <input
                  className="HoraFSensor4"
                  type="text"
                  id="HoraFSensor4"
                  placeholder="00:00:00"
                  name="HoraFSensor4"
                />
                <input
                  className="HoraFSensor4"
                  type="text"
                  id="HoraFSensor4"
                  placeholder="00:00:00"
                  name="HoraFSensor4"
                />
              </span>
              <span>
                <label htmlFor="TemMaxSensor4">Temp. Máx. </label>
                <input
                  className="TemMaxSensor4"
                  type="text"
                  id="TemMaxSensor4"
                  placeholder="4,0"
                  name="TemMaxSensor4"
                />
                <input
                  className="TemMaxSensor4"
                  type="text"
                  id="TemMaxSensor4"
                  placeholder="4,0"
                  name="TemMaxSensor4"
                />
                <input
                  className="TemMaxSensor4"
                  type="text"
                  id="TemMaxSensor4"
                  placeholder="4,0"
                  name="TemMaxSensor4"
                />
              </span>
              <span>
                <label htmlFor="TemMinSensor4">Temp. Min. </label>
                <input
                  className="TemMinSensor4"
                  type="text"
                  id="TemMinSensor4"
                  placeholder="2,0"
                  name="TemMinSensor4"
                />
                <input
                  className="TemMinSensor4"
                  type="text"
                  id="TemMinSensor4"
                  placeholder="2,0"
                  name="TemMinSensor4"
                />
                <input
                  className="TemMinSensor4"
                  type="text"
                  id="TemMinSensor4"
                  placeholder="2,0"
                  name="TemMinSensor4"
                />
              </span>
              <span>
                <label htmlFor="TemPromSensor4">Temp. Promedio </label>
                <input
                  className="TemPromSensor4"
                  type="text"
                  id="TemPromSensor4"
                  placeholder="2,9"
                  name="TemPromSensor4"
                />
                <input
                  className="TemPromSensor4"
                  type="text"
                  id="TemPromSensor4"
                  placeholder="2,9"
                  name="TemPromSensor4"
                />
                <input
                  className="TemPromSensor4"
                  type="text"
                  id="TemPromSensor4"
                  placeholder="2,9"
                  name="TemPromSensor4"
                />
              </span>
              <span>
                <label htmlFor="DevEstSensor4">Desv. Estandar </label>
                <input
                  className="DevEstSensor4"
                  type="text"
                  id="DevEstSensor4"
                  placeholder="0,6"
                  name="DevEstSensor4"
                />
                <input
                  className="DevEstSensor4"
                  type="text"
                  id="DevEstSensor4"
                  placeholder="0,6"
                  name="DevEstSensor4"
                />
                <input
                  className="DevEstSensor4"
                  type="text"
                  id="DevEstSensor4"
                  placeholder="0,6"
                  name="DevEstSensor4"
                />
              </span>
              <span className="cumpleSensor">
                <label htmlFor="CumpleSensor4">Cumple</label>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
              </span>
            </form>
          </dd>
          <dt>Sensor 5. + #serie</dt>
          <dd>
            <form className="sensor" name="formularisensor1">
              <span>
                <label htmlFor="Sensor5">Periodo</label>
                <label htmlFor="FechaSensor5">1</label>
                <label htmlFor="FechaSensor5">2</label>
                <label htmlFor="FechaSensor5">3</label>
              </span>
              <span>
                <label htmlFor="FechaSensor5">Fecha de Inicio</label>
                <input
                  className="FechaSensor5"
                  type="text"
                  id="FechaSensor5"
                  placeholder="01-Ene-2021"
                  name="FechaSensor5"
                />
                <input
                  className="FechaSensor5"
                  type="text"
                  id="FechaSensor5"
                  placeholder="01-Ene-2021"
                  name="FechaSensor5"
                />
                <input
                  className="FechaSensor5"
                  type="text"
                  id="FechaSensor5"
                  placeholder="01-Ene-2021"
                  name="FechaSensor5"
                />
              </span>
              <span>
                <label htmlFor="HoraSensor5">Hora de Inicio</label>
                <input
                  className="HoraSensor5"
                  type="text"
                  id="HoraSensor5"
                  placeholder="00:00:00"
                  name="HoraSensor5"
                />
                <input
                  className="HoraSensor5"
                  type="text"
                  id="HoraSensor5"
                  placeholder="00:00:00"
                  name="HoraSensor5"
                />
                <input
                  className="HoraSensor5"
                  type="text"
                  id="HoraSensor5"
                  placeholder="00:00:00"
                  name="HoraSensor5"
                />
              </span>
              <span>
                <label htmlFor="FechaFSensor5">Fecha de Final</label>
                <input
                  className="FechaFSensor5"
                  type="text"
                  id="FechaFSensor5"
                  placeholder="01-Ene-2021"
                  name="FechaFSensor5"
                />
                <input
                  className="FechaFSensor5"
                  type="text"
                  id="FechaFSensor5"
                  placeholder="01-Ene-2021"
                  name="FechaFSensor5"
                />
                <input
                  className="FechaFSensor5"
                  type="text"
                  id="FechaFSensor5"
                  placeholder="01-Ene-2021"
                  name="FechaFSensor5"
                />
              </span>
              <span>
                <label htmlFor="HoraFSensor5">Hora de Final</label>
                <input
                  className="HoraFSensor5"
                  type="text"
                  id="HoraFSensor5"
                  placeholder="00:00:00"
                  name="HoraFSensor5"
                />
                <input
                  className="HoraFSensor5"
                  type="text"
                  id="HoraFSensor5"
                  placeholder="00:00:00"
                  name="HoraFSensor5"
                />
                <input
                  className="HoraFSensor5"
                  type="text"
                  id="HoraFSensor5"
                  placeholder="00:00:00"
                  name="HoraFSensor5"
                />
              </span>
              <span>
                <label htmlFor="TemMaxSensor5">Temp. Máx. </label>
                <input
                  className="TemMaxSensor5"
                  type="text"
                  id="TemMaxSensor5"
                  placeholder="4,0"
                  name="TemMaxSensor5"
                />
                <input
                  className="TemMaxSensor5"
                  type="text"
                  id="TemMaxSensor5"
                  placeholder="4,0"
                  name="TemMaxSensor5"
                />
                <input
                  className="TemMaxSensor5"
                  type="text"
                  id="TemMaxSensor5"
                  placeholder="4,0"
                  name="TemMaxSensor5"
                />
              </span>
              <span>
                <label htmlFor="TemMinSensor5">Temp. Min. </label>
                <input
                  className="TemMinSensor5"
                  type="text"
                  id="TemMinSensor5"
                  placeholder="2,0"
                  name="TemMinSensor5"
                />
                <input
                  className="TemMinSensor5"
                  type="text"
                  id="TemMinSensor5"
                  placeholder="2,0"
                  name="TemMinSensor5"
                />
                <input
                  className="TemMinSensor5"
                  type="text"
                  id="TemMinSensor5"
                  placeholder="2,0"
                  name="TemMinSensor5"
                />
              </span>
              <span>
                <label htmlFor="TemPromSensor5">Temp. Promedio </label>
                <input
                  className="TemPromSensor5"
                  type="text"
                  id="TemPromSensor5"
                  placeholder="2,9"
                  name="TemPromSensor5"
                />
                <input
                  className="TemPromSensor5"
                  type="text"
                  id="TemPromSensor5"
                  placeholder="2,9"
                  name="TemPromSensor5"
                />
                <input
                  className="TemPromSensor5"
                  type="text"
                  id="TemPromSensor5"
                  placeholder="2,9"
                  name="TemPromSensor5"
                />
              </span>
              <span>
                <label htmlFor="DevEstSensor5">Desv. Estandar </label>
                <input
                  className="DevEstSensor5"
                  type="text"
                  id="DevEstSensor5"
                  placeholder="0,6"
                  name="DevEstSensor5"
                />
                <input
                  className="DevEstSensor5"
                  type="text"
                  id="DevEstSensor5"
                  placeholder="0,6"
                  name="DevEstSensor5"
                />
                <input
                  className="DevEstSensor5"
                  type="text"
                  id="DevEstSensor5"
                  placeholder="0,6"
                  name="DevEstSensor5"
                />
              </span>
              <span className="cumpleSensor">
                <label htmlFor="CumpleSensor5">Cumple</label>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
              </span>
            </form>
          </dd>
          <dt>Sensor 6. + #serie</dt>
          <dd>
            <form className="sensor" name="formularisensor1">
              <span>
                <label htmlFor="Sensor6">Periodo</label>
                <label htmlFor="FechaSensor6">1</label>
                <label htmlFor="FechaSensor6">2</label>
                <label htmlFor="FechaSensor6">3</label>
              </span>
              <span>
                <label htmlFor="FechaSensor6">Fecha de Inicio</label>
                <input
                  className="FechaSensor6"
                  type="text"
                  id="FechaSensor6"
                  placeholder="01-Ene-2021"
                  name="FechaSensor6"
                />
                <input
                  className="FechaSensor6"
                  type="text"
                  id="FechaSensor6"
                  placeholder="01-Ene-2021"
                  name="FechaSensor6"
                />
                <input
                  className="FechaSensor6"
                  type="text"
                  id="FechaSensor6"
                  placeholder="01-Ene-2021"
                  name="FechaSensor6"
                />
              </span>
              <span>
                <label htmlFor="HoraSensor6">Hora de Inicio</label>
                <input
                  className="HoraSensor6"
                  type="text"
                  id="HoraSensor6"
                  placeholder="00:00:00"
                  name="HoraSensor6"
                />
                <input
                  className="HoraSensor6"
                  type="text"
                  id="HoraSensor6"
                  placeholder="00:00:00"
                  name="HoraSensor6"
                />
                <input
                  className="HoraSensor6"
                  type="text"
                  id="HoraSensor6"
                  placeholder="00:00:00"
                  name="HoraSensor6"
                />
              </span>
              <span>
                <label htmlFor="FechaFSensor6">Fecha de Final</label>
                <input
                  className="FechaFSensor6"
                  type="text"
                  id="FechaFSensor6"
                  placeholder="01-Ene-2021"
                  name="FechaFSensor6"
                />
                <input
                  className="FechaFSensor6"
                  type="text"
                  id="FechaFSensor6"
                  placeholder="01-Ene-2021"
                  name="FechaFSensor6"
                />
                <input
                  className="FechaFSensor6"
                  type="text"
                  id="FechaFSensor6"
                  placeholder="01-Ene-2021"
                  name="FechaFSensor6"
                />
              </span>
              <span>
                <label htmlFor="HoraFSensor6">Hora de Final</label>
                <input
                  className="HoraFSensor6"
                  type="text"
                  id="HoraFSensor6"
                  placeholder="00:00:00"
                  name="HoraFSensor6"
                />
                <input
                  className="HoraFSensor6"
                  type="text"
                  id="HoraFSensor6"
                  placeholder="00:00:00"
                  name="HoraFSensor6"
                />
                <input
                  className="HoraFSensor6"
                  type="text"
                  id="HoraFSensor6"
                  placeholder="00:00:00"
                  name="HoraFSensor6"
                />
              </span>
              <span>
                <label htmlFor="TemMaxSensor6">Temp. Máx. </label>
                <input
                  className="TemMaxSensor6"
                  type="text"
                  id="TemMaxSensor6"
                  placeholder="4,0"
                  name="TemMaxSensor6"
                />
                <input
                  className="TemMaxSensor6"
                  type="text"
                  id="TemMaxSensor6"
                  placeholder="4,0"
                  name="TemMaxSensor6"
                />
                <input
                  className="TemMaxSensor6"
                  type="text"
                  id="TemMaxSensor6"
                  placeholder="4,0"
                  name="TemMaxSensor6"
                />
              </span>
              <span>
                <label htmlFor="TemMinSensor6">Temp. Min. </label>
                <input
                  className="TemMinSensor6"
                  type="text"
                  id="TemMinSensor6"
                  placeholder="2,0"
                  name="TemMinSensor6"
                />
                <input
                  className="TemMinSensor6"
                  type="text"
                  id="TemMinSensor6"
                  placeholder="2,0"
                  name="TemMinSensor6"
                />
                <input
                  className="TemMinSensor6"
                  type="text"
                  id="TemMinSensor6"
                  placeholder="2,0"
                  name="TemMinSensor6"
                />
              </span>
              <span>
                <label htmlFor="TemPromSensor6">Temp. Promedio </label>
                <input
                  className="TemPromSensor6"
                  type="text"
                  id="TemPromSensor6"
                  placeholder="2,9"
                  name="TemPromSensor6"
                />
                <input
                  className="TemPromSensor6"
                  type="text"
                  id="TemPromSensor6"
                  placeholder="2,9"
                  name="TemPromSensor6"
                />
                <input
                  className="TemPromSensor6"
                  type="text"
                  id="TemPromSensor6"
                  placeholder="2,9"
                  name="TemPromSensor6"
                />
              </span>
              <span>
                <label htmlFor="DevEstSensor6">Desv. Estandar </label>
                <input
                  className="DevEstSensor6"
                  type="text"
                  id="DevEstSensor6"
                  placeholder="0,6"
                  name="DevEstSensor6"
                />
                <input
                  className="DevEstSensor6"
                  type="text"
                  id="DevEstSensor6"
                  placeholder="0,6"
                  name="DevEstSensor6"
                />
                <input
                  className="DevEstSensor6"
                  type="text"
                  id="DevEstSensor6"
                  placeholder="0,6"
                  name="DevEstSensor6"
                />
              </span>
              <span className="cumpleSensor">
                <label htmlFor="CumpleSensor6">Cumple</label>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
              </span>
            </form>
          </dd>
          <dt>Sensor 7. + #serie Temperatura & Humedad Ambiente </dt>
          <dd>
            <form className="sensor" name="formularisensor1">
              <span>
                <label htmlFor="Sensor7">Periodo</label>
                <label htmlFor="FechaSensor7">1</label>
                <label htmlFor="FechaSensor7">2</label>
                <label htmlFor="FechaSensor7">3</label>
              </span>
              <span>
                <label htmlFor="FechaSensor7">Fecha de Inicio</label>
                <input
                  className="FechaSensor7"
                  type="text"
                  id="FechaSensor7"
                  placeholder="01-Ene-2021"
                  name="FechaSensor7"
                />
                <input
                  className="FechaSensor7"
                  type="text"
                  id="FechaSensor7"
                  placeholder="01-Ene-2021"
                  name="FechaSensor7"
                />
                <input
                  className="FechaSensor7"
                  type="text"
                  id="FechaSensor7"
                  placeholder="01-Ene-2021"
                  name="FechaSensor7"
                />
              </span>
              <span>
                <label htmlFor="HoraSensor7">Hora de Inicio</label>
                <input
                  className="HoraSensor7"
                  type="text"
                  id="HoraSensor7"
                  placeholder="00:00:00"
                  name="HoraSensor7"
                />
                <input
                  className="HoraSensor7"
                  type="text"
                  id="HoraSensor7"
                  placeholder="00:00:00"
                  name="HoraSensor7"
                />
                <input
                  className="HoraSensor7"
                  type="text"
                  id="HoraSensor7"
                  placeholder="00:00:00"
                  name="HoraSensor7"
                />
              </span>
              <span>
                <label htmlFor="FechaFSensor7">Fecha de Final</label>
                <input
                  className="FechaFSensor7"
                  type="text"
                  id="FechaFSensor7"
                  placeholder="01-Ene-2021"
                  name="FechaFSensor7"
                />
                <input
                  className="FechaFSensor7"
                  type="text"
                  id="FechaFSensor7"
                  placeholder="01-Ene-2021"
                  name="FechaFSensor7"
                />
                <input
                  className="FechaFSensor7"
                  type="text"
                  id="FechaFSensor7"
                  placeholder="01-Ene-2021"
                  name="FechaFSensor7"
                />
              </span>
              <span>
                <label htmlFor="HoraFSensor7">Hora de Final</label>
                <input
                  className="HoraFSensor7"
                  type="text"
                  id="HoraFSensor7"
                  placeholder="00:00:00"
                  name="HoraFSensor7"
                />
                <input
                  className="HoraFSensor7"
                  type="text"
                  id="HoraFSensor7"
                  placeholder="00:00:00"
                  name="HoraFSensor7"
                />
                <input
                  className="HoraFSensor7"
                  type="text"
                  id="HoraFSensor7"
                  placeholder="00:00:00"
                  name="HoraFSensor7"
                />
              </span>
              <span>
                <label htmlFor="TemMaxSensor7">Temp. Máx. </label>
                <input
                  className="TemMaxSensor7"
                  type="text"
                  id="TemMaxSensor7"
                  placeholder="4,0"
                  name="TemMaxSensor7"
                />
                <input
                  className="TemMaxSensor7"
                  type="text"
                  id="TemMaxSensor7"
                  placeholder="4,0"
                  name="TemMaxSensor7"
                />
                <input
                  className="TemMaxSensor7"
                  type="text"
                  id="TemMaxSensor7"
                  placeholder="4,0"
                  name="TemMaxSensor7"
                />
              </span>
              <span>
                <label htmlFor="TemMinSensor7">Temp. Min. </label>
                <input
                  className="TemMinSensor7"
                  type="text"
                  id="TemMinSensor7"
                  placeholder="2,0"
                  name="TemMinSensor7"
                />
                <input
                  className="TemMinSensor7"
                  type="text"
                  id="TemMinSensor7"
                  placeholder="2,0"
                  name="TemMinSensor7"
                />
                <input
                  className="TemMinSensor7"
                  type="text"
                  id="TemMinSensor7"
                  placeholder="2,0"
                  name="TemMinSensor7"
                />
              </span>
              <span>
                <label htmlFor="TemPromSensor7">Temp. Promedio </label>
                <input
                  className="TemPromSensor7"
                  type="text"
                  id="TemPromSensor7"
                  placeholder="2,9"
                  name="TemPromSensor7"
                />
                <input
                  className="TemPromSensor7"
                  type="text"
                  id="TemPromSensor7"
                  placeholder="2,9"
                  name="TemPromSensor7"
                />
                <input
                  className="TemPromSensor7"
                  type="text"
                  id="TemPromSensor7"
                  placeholder="2,9"
                  name="TemPromSensor7"
                />
              </span>
              <span>
                <label htmlFor="DevEstSensor7">Desv. Estandar </label>
                <input
                  className="DevEstSensor7"
                  type="text"
                  id="DevEstSensor7"
                  placeholder="0,6"
                  name="DevEstSensor7"
                />
                <input
                  className="DevEstSensor7"
                  type="text"
                  id="DevEstSensor7"
                  placeholder="0,6"
                  name="DevEstSensor7"
                />
                <input
                  className="DevEstSensor7"
                  type="text"
                  id="DevEstSensor7"
                  placeholder="0,6"
                  name="DevEstSensor7"
                />
              </span>
              <span className="cumpleSensor">
                <label htmlFor="CumpleSensor7">Cumple</label>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
              </span>
            </form>
          </dd>
          <dt> </dt>
          <dd>
            <form className="sensor" name="formularisensor1">
              <span>
                <label htmlFor="Sensor7">Periodo</label>
                <label htmlFor="FechaSensor7">1</label>
                <label htmlFor="FechaSensor7">2</label>
                <label htmlFor="FechaSensor7">3</label>
              </span>
              <span>
                <label htmlFor="FechaSensor7">Fecha de Inicio</label>
                <input
                  className="FechaSensor7"
                  type="text"
                  id="FechaSensor7"
                  placeholder="01-Ene-2021"
                  name="FechaSensor7"
                />
                <input
                  className="FechaSensor7"
                  type="text"
                  id="FechaSensor7"
                  placeholder="01-Ene-2021"
                  name="FechaSensor7"
                />
                <input
                  className="FechaSensor7"
                  type="text"
                  id="FechaSensor7"
                  placeholder="01-Ene-2021"
                  name="FechaSensor7"
                />
              </span>
              <span>
                <label htmlFor="HoraSensor7">Hora de Inicio</label>
                <input
                  className="HoraSensor7"
                  type="text"
                  id="HoraSensor7"
                  placeholder="00:00:00"
                  name="HoraSensor7"
                />
                <input
                  className="HoraSensor7"
                  type="text"
                  id="HoraSensor7"
                  placeholder="00:00:00"
                  name="HoraSensor7"
                />
                <input
                  className="HoraSensor7"
                  type="text"
                  id="HoraSensor7"
                  placeholder="00:00:00"
                  name="HoraSensor7"
                />
              </span>
              <span>
                <label htmlFor="FechaFSensor7">Fecha de Final</label>
                <input
                  className="FechaFSensor7"
                  type="text"
                  id="FechaFSensor7"
                  placeholder="01-Ene-2021"
                  name="FechaFSensor7"
                />
                <input
                  className="FechaFSensor7"
                  type="text"
                  id="FechaFSensor7"
                  placeholder="01-Ene-2021"
                  name="FechaFSensor7"
                />
                <input
                  className="FechaFSensor7"
                  type="text"
                  id="FechaFSensor7"
                  placeholder="01-Ene-2021"
                  name="FechaFSensor7"
                />
              </span>
              <span>
                <label htmlFor="HoraFSensor7">Hora de Final</label>
                <input
                  className="HoraFSensor7"
                  type="text"
                  id="HoraFSensor7"
                  placeholder="00:00:00"
                  name="HoraFSensor7"
                />
                <input
                  className="HoraFSensor7"
                  type="text"
                  id="HoraFSensor7"
                  placeholder="00:00:00"
                  name="HoraFSensor7"
                />
                <input
                  className="HoraFSensor7"
                  type="text"
                  id="HoraFSensor7"
                  placeholder="00:00:00"
                  name="HoraFSensor7"
                />
              </span>
              <span>
                <label htmlFor="HumMaxSensor7">Humedad Max. </label>
                <input
                  className="HumMaxSensor7"
                  type="text"
                  id="HumMaxSensor7"
                  placeholder="4,0"
                  name="HumMaxSensor7"
                />
                <input
                  className="HumMaxSensor7"
                  type="text"
                  id="HumMaxSensor7"
                  placeholder="4,0"
                  name="HumMaxSensor7"
                />
                <input
                  className="HumMaxSensor7"
                  type="text"
                  id="HumMaxSensor7"
                  placeholder="4,0"
                  name="HumMaxSensor7"
                />
              </span>
              <span>
                <label htmlFor="HumMinSensor7">Humedad Min. </label>
                <input
                  className="HumMinSensor7"
                  type="text"
                  id="HumMinSensor7"
                  placeholder="2,0"
                  name="HumMinSensor7"
                />
                <input
                  className="HumMinSensor7"
                  type="text"
                  id="HumMinSensor7"
                  placeholder="2,0"
                  name="HumMinSensor7"
                />
                <input
                  className="HumMinSensor7"
                  type="text"
                  id="HumMinSensor7"
                  placeholder="2,0"
                  name="HumMinSensor7"
                />
              </span>
              <span>
                <label htmlFor="HumPromSensor7">Humedad Promedio </label>
                <input
                  className="HumPromSensor7"
                  type="text"
                  id="HumPromSensor7"
                  placeholder="2,9"
                  name="HumPromSensor7"
                />
                <input
                  className="HumPromSensor7"
                  type="text"
                  id="HumPromSensor7"
                  placeholder="2,9"
                  name="HumPromSensor7"
                />
                <input
                  className="HumPromSensor7"
                  type="text"
                  id="HumPromSensor7"
                  placeholder="2,9"
                  name="HumPromSensor7"
                />
              </span>
              <span>
                <label htmlFor="DevEstSensor7">Desv. Estandar </label>
                <input
                  className="DevEstSensor7"
                  type="text"
                  id="DevEstSensor7"
                  placeholder="0,1"
                  name="DevEstSensor7"
                />
                <input
                  className="DevEstSensor7"
                  type="text"
                  id="DevEstSensor7"
                  placeholder="0,1"
                  name="DevEstSensor7"
                />
                <input
                  className="DevEstSensor7"
                  type="text"
                  id="DevEstSensor7"
                  placeholder="0,1"
                  name="DevEstSensor7"
                />
              </span>
              <span className="cumpleSensor">
                <label htmlFor="CumpleSensor7">Cumple</label>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
              </span>
            </form>
          </dd>
        </dd>
        <dd>
          <dt> </dt>
          <form className="seriesensores">
            <span className="noSerieSensor">
              <label htmlFor="Sensor">Sensor</label>
              <input
                className="SensorSerie1"
                type="text"
                id="SensorSerie1"
                placeholder="#Serie Sensor 1"
                name="SensorSerie1"
              />
              <input
                className="SensorSerie2"
                type="text"
                id="SensorSerie2"
                placeholder="#Serie Sensor 2"
                name="SensorSerie2"
              />
              <input
                className="SensorSerie3"
                type="text"
                id="SensorSerie3"
                placeholder="#Serie Sensor 3"
                name="SensorSerie3"
              />
              <input
                className="SensorSerie4"
                type="text"
                id="SensorSerie4"
                placeholder="#Serie Sensor 4"
                name="SensorSerie4"
              />
              <input
                className="SensorSerie5"
                type="text"
                id="SensorSerie5"
                placeholder="#Serie Sensor 5"
                name="SensorSerie5"
              />
              <input
                className="SensorSerie6"
                type="text"
                id="SensorSerie6"
                placeholder="#Serie Sensor 6"
                name="SensorSerie6"
              />
            </span>
            <span className="periodoEvaluado">
              <label htmlFor="PeriodoEvaluado">Periodo Evaluado</label>
              <span className="labelsperiodo">
                <label htmlFor="1">1</label>
                <label htmlFor="2">2</label>
                <label htmlFor="1">1</label>
                <label htmlFor="2">2</label>
                <label htmlFor="1">1</label>
                <label htmlFor="2">2</label>
                <label htmlFor="1">1</label>
                <label htmlFor="2">2</label>
                <label htmlFor="1">1</label>
                <label htmlFor="2">2</label>
                <label htmlFor="1">1</label>
                <label htmlFor="2">2</label>
              </span>
            </span>
            <span className="tempMax">
              <label htmlFor="TempMax">Temp. Max °C</label>
              <input
                className="Tempmaxs1"
                type="text"
                id="Tempmaxs1"
                placeholder="Temp. Max. Sensor1"
                name="Tempmaxs1"
              />
              <input
                className="Tempmaxs2"
                type="text"
                id="Tempmaxs2"
                placeholder="Temp. Max. Sensor1"
                name="Tempmaxs2"
              />
              <input
                className="Tempmaxs3"
                type="text"
                id="Tempmaxs3"
                placeholder="Temp. Max. Sensor2"
                name="Tempmaxs3"
              />
              <input
                className="Tempmaxs4"
                type="text"
                id="Tempmaxs4"
                placeholder="Temp. Max. Sensor2"
                name="Tempmaxs4"
              />
              <input
                className="Tempmaxs5"
                type="text"
                id="Tempmaxs5"
                placeholder="Temp. Max. Sensor3"
                name="Tempmaxs5"
              />
              <input
                className="Tempmaxs6"
                type="text"
                id="Tempmaxs6"
                placeholder="Temp. Max. Sensor3"
                name="Tempmaxs6"
              />
              <input
                className="Tempmaxs7"
                type="text"
                id="Tempmaxs7"
                placeholder="Temp. Max. Sensor4"
                name="Tempmaxs7"
              />
              <input
                className="Tempmaxs8"
                type="text"
                id="Tempmaxs8"
                placeholder="Temp. Max. Sensor4"
                name="Tempmaxs8"
              />
              <input
                className="Tempmaxs9"
                type="text"
                id="Tempmaxs9"
                placeholder="Temp. Max. Sensor5"
                name="Tempmaxs9"
              />
              <input
                className="Tempmaxs10"
                type="text"
                id="Tempmaxs10"
                placeholder="Temp. Max. Sensor5"
                name="Tempmaxs10"
              />
              <input
                className="Tempmaxs11"
                type="text"
                id="Tempmaxs11"
                placeholder="Temp. Max. Sensor6"
                name="Tempmaxs11"
              />
              <input
                className="Tempmaxs12"
                type="text"
                id="Tempmaxs12"
                placeholder="Temp. Max. Sensor6"
                name="Tempmaxs12"
              />
            </span>
            <span className="TempMinSensores">
              <label htmlFor="tempMin">Temp. Min. °C</label>
              <input
                className="Tempmins1"
                type="text"
                id="Tempmins1"
                placeholder="Temp. Min. Sensor1"
                name="Tempmins1"
              />
              <input
                className="Tempmins2"
                type="text"
                id="Tempmins2"
                placeholder="Temp. Min. Sensor1"
                name="Tempmins2"
              />
              <input
                className="Tempmins3"
                type="text"
                id="Tempmins3"
                placeholder="Temp. Min. Sensor2"
                name="Tempmins3"
              />
              <input
                className="Tempmins4"
                type="text"
                id="Tempmins4"
                placeholder="Temp. Min. Sensor2"
                name="Tempmins4"
              />
              <input
                className="Tempmins5"
                type="text"
                id="Tempmins5"
                placeholder="Temp. Min. Sensor3"
                name="Tempmins5"
              />
              <input
                className="Tempmins6"
                type="text"
                id="Tempmins6"
                placeholder="Temp. Min. Sensor3"
                name="Tempmins6"
              />
              <input
                className="Tempmins7"
                type="text"
                id="Tempmins7"
                placeholder="Temp. Min. Sensor4"
                name="Tempmins7"
              />
              <input
                className="Tempmins8"
                type="text"
                id="Tempmins8"
                placeholder="Temp. Min. Sensor4"
                name="Tempmins8"
              />
              <input
                className="Tempmins9"
                type="text"
                id="Tempmins9"
                placeholder="Temp. Min. Sensor5"
                name="Tempmins9"
              />
              <input
                className="Tempmins10"
                type="text"
                id="Tempmins10"
                placeholder="Temp. Min. Sensor5"
                name="Tempmins10"
              />
              <input
                className="Tempmins11"
                type="text"
                id="Tempmins11"
                placeholder="Temp. Min. Sensor6"
                name="Tempmins11"
              />
              <input
                className="Tempmins12"
                type="text"
                id="Tempmins12"
                placeholder="Temp. Min. Sensor6"
                name="Tempmins12"
              />
            </span>
            <span className="TempPromSensores">
              <label htmlFor="tempProm">Temp. Prom. °C</label>
              <input
                className="Tempproms1"
                type="text"
                id="Tempproms1"
                placeholder="Temp. Prom. Sensor1"
                name="Tempproms1"
              />
              <input
                className="Tempproms2"
                type="text"
                id="Tempproms2"
                placeholder="Temp. Prom. Sensor1"
                name="Tempproms2"
              />
              <input
                className="Tempproms3"
                type="text"
                id="Tempproms3"
                placeholder="Temp. Prom. Sensor2"
                name="Tempproms3"
              />
              <input
                className="Tempproms4"
                type="text"
                id="Tempproms4"
                placeholder="Temp. Prom. Sensor2"
                name="Tempproms4"
              />
              <input
                className="Tempproms5"
                type="text"
                id="Tempproms5"
                placeholder="Temp. Prom. Sensor3"
                name="Tempproms5"
              />
              <input
                className="Tempproms6"
                type="text"
                id="Tempproms6"
                placeholder="Temp. Prom. Sensor3"
                name="Tempproms6"
              />
              <input
                className="Tempproms7"
                type="text"
                id="Tempproms7"
                placeholder="Temp. Prom. Sensor4"
                name="Tempproms7"
              />
              <input
                className="Tempproms8"
                type="text"
                id="Tempproms8"
                placeholder="Temp. Prom. Sensor4"
                name="Tempproms8"
              />
              <input
                className="Tempproms9"
                type="text"
                id="Tempproms9"
                placeholder="Temp. Prom. Sensor5"
                name="Tempproms9"
              />
              <input
                className="Tempproms10"
                type="text"
                id="Tempproms10"
                placeholder="Temp. Prom. Sensor5"
                name="Tempproms10"
              />
              <input
                className="Tempproms11"
                type="text"
                id="Tempproms11"
                placeholder="Temp. Prom. Sensor6"
                name="Tempproms11"
              />
              <input
                className="Tempproms12"
                type="text"
                id="Tempproms12"
                placeholder="Temp. Prom. Sensor6"
                name="Tempproms12"
              />
            </span>
            <span className="cumpleSensor14">
              <label htmlFor="Cumple">Cumple</label>
              <span className="containerCumple">
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
              </span>
            </span>
          </form>
        </dd>
      </dl>
    </div>
  );
};
