import './Header.scss';
import React from 'react';
import logobio from '../../assets/imgs/logo-Biored-red.png'
import { Link } from 'react-router-dom';

export default function Header (props) {
    return(
        <div className="container-heaader">
            <span className="container-image">
                <Link to="/Home">
                <img src={logobio} alt="Logo Biored" />
                </Link>
            </span>
            <span className="container-title">
                <h2 className="title-procesos">PROCESOS OPERATIVOS SGC</h2>
                <h2 className="title-registro">{ props.title }</h2>
            </span>
            <span className="container-paginado">
                <h4>{ props.pagina }</h4>
                <h2>
                CONFIDENCIAL USO INSTITUCIONAL 
                </h2>
            </span>
        </div>
    )
}