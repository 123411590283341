import './EncuestaClinete.scss'
import React from 'react';

export default function EncuestaCliente (){
    return(
        <div className="container-encuesta">
          <form className="form-encuesta" name="formulario-encuesta" method="post" action="/">
            <span>Pregunta</span><span>Resouesta del Cliente</span>
            <label htmlFor="ProductoAlmacenado">1. Cuál es el producto que almacena el equipo normalmente</label>  
            <input className="productoAlmacenado" type="text" id="ProductoAlmacenado" placeholder="Producto Almacenado" name="ProductoAlmacenado" />
            
            <label htmlFor="TemperaturaProducto">2. Cuál es la temperatura de carga del producto</label>
            <input className="temperaturaProducto" type="text" id="TemperaturaProducto" placeholder="Temperatura del Producto" name="TemperaturaProducto" />
            
            <label htmlFor="IntervaloApt">3. Intervalo de tiempo aproximado entre aperturas</label>
            <input className="IntervaloApt" type="text" id="IntervaloApt" placeholder="Intervalo de apertura de la puerta" name="IntervaloApt" />
            
            <label htmlFor="TiempoRetiroProducto">4. Tiempo de duración aproximado para carga y retiro de producto</label>
            <input className="tiempoRetiroProducto" type="text" id="TiempoRetiroProducto" placeholder="Tiempo para hacer retiro del producto" name="TiempoRetiroProducto" />
            
            
            <label htmlFor="ValoresAlarma">5. Cuáles son los valores de alarma configurados  en  el equipo</label>
            <input className="valoresAlarma" type="text" id="ValoresAlarma" placeholder="Valores de Alarma" name="ValoresAlarma" />
            
            
            <label htmlFor="UtilizacionEquipo">6. El equipo es utilizado para cargar otros productos con rango de operación distinto?</label>
            <input className="utilizacionEquipo" type="text" id="UtilizacionEquipo" placeholder="Utilizacion del Equipo" name="UtilizacionEquipo" />
            
            <label htmlFor="TemperaturaIngreso">7. Si la respuesta es sí, a qué temperatura ingresan?</label>
            <input className="temperaturaIngreso" type="text" id="TemperaturaIngreso" placeholder="Temperatura de ingreso del producto" name="TemperaturaIngreso" />

            <label htmlFor="TiempoConservacion">8. En caso de fallo del equipo, cuál es el tiempo estimado para que el producto pueda estar fuera de rango de temperatura sin perder sus propiedades</label>
            <input className="tiempoConservacion" type="text" id="TiempoConservacion" placeholder="Tiempo de conservacion del producto" name="TiempoConservacion" />
            
            <label htmlFor="TiempoRetiroProducto">9.Cual es el tiempo  establecido para retirar el producto del equipo  cuando este falle</label>
            <input className="tiempoRetiroProducto" type="text" id="TiempoRetiroProducto" placeholder="Tiempo de retiro del producto" name="TiempoRetiroProducto" />


            <label htmlFor="FichasTecnicas">10.Solicitar las fichas técnicas o manuales de los equipos para  verificar  características  de trabajo</label>
            <input className="fichasTecnicas" type="text" id="FichasTecnicas" placeholder="Fichas tecnicas o manuales del equipo" name="FichasTecnicas" />

            </form>
            <button>enviar</button>

            

            <section className="containerFinal">
            <div>
                <h2>REPRESENTANTE DEL CLIENTE  QUIEN SUMINISTRA DATOS:</h2>
                <form className="form-registro-encuesta" name="formulario" method="post" action="/">
            
            <label htmlFor="Cliente">Cliente</label>  
            <input className="cliente" type="text" id="Cliente" placeholder="Ingrese Cliente" name="Cliente" />
            
            <label htmlFor="Ciudad">Ciudad</label>
            <input className="ciudad" type="text" id="Ciudad" placeholder="Ingrese Ciudad" name="Ciudad" />
            
            <label htmlFor="No-informe">No de informe</label>
            <input className="no-informe" type="text" id="No-informe" placeholder="Ingrese # de informe" name="No-de-informe" />
            </form>
            </div>
            <div>
                <h2>OBSERVACIONES  DEL CLIENTE </h2>
                
                <input type="text" className="observacionesCliente" name="ObservacionesCliente" id="ObservacionesCliente" placeholder="Observaciones del cliente" />
            </div>
            
            </section>
            <button>enviar</button>
        </div>
    )
}