import axios from "axios";
import { useEffect, useState } from "react";

export const AuthData = (url) => {
  const baseUrl = "https://pruebas2.bioredingenieria.com/auth.php";
  const [auth, setAuth] = useState([]);

  useEffect(() => {
    const optionsRequire = {
      method: "POST",
      body: {
        "usuario": "usuario1@gmail.com",
        "password": "123456",
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "usuario": "usuario1@gmail.com",
        "password": "123456",
      },
    };
    axios.post(`${baseUrl}auth.php`, optionsRequire)
    //   .then((response) => response.json)
      .then((data) => setAuth(data));
  }, [baseUrl]);
  console.log(auth);
};
