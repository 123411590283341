import "./Home.scss"
import React from 'react';
import Header from '../../components/Header/Header';
import Nav from "../../components/Nav/Nav";
import { Link } from 'react-router-dom';
import  AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import  Assignment from '@material-ui/icons/Assignment';


export default function Home() {
    return(
        <div>
            <Header pagina="HomePage" />
            <Nav serie="R-DCI-01" version="Version 1" fecha="Julio 2021" />
            <div className="container-home">
            <h1>PAGINA REGISTRO Y CERTIFICACION BIORED</h1>
            <Link to="/PageRegistro1">
            <h2>Formulario de Registro</h2>
            <Assignment />
            </Link>
            <Link to="/Certificados">
            <h2>Certificaciones</h2>
            <AssignmentTurnedInIcon />
            </Link>
            </div>
        </div>
    )
}