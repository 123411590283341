import './Nav.scss'
import React from 'react';

export default function NavBlue (props) {
    return (
        <div className="container-nav-blue">
            <span className="container-info">
                <h3>{ props.title }</h3>
            </span>
        </div>
    )
}