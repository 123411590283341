import "./VerificarDatos.scss";
import React from 'react';

export default function VerificarDatos () {
    return(
        <div>
             <form className="form-verificadatos" name="formulario" method="post" action="/">
            
            <label htmlFor="Fecha">Fecha</label>  
            <input className="fecha" type="text" id="Fecha" placeholder="Ingrese Fecha" name="Fecha" />
            
            <label htmlFor="Hora">Hora</label>
            <input className="hora" type="text" id="Hora" placeholder="Ingrese Hora" name="Hora" />
            
            <label htmlFor="Temp-del-display">Temp del display</label>
            <input className="temp-del-display" type="text" id="Temp-del-display" placeholder="Ingrese Temp del display" name="Temp-del-display" />
            
            <label htmlFor="Fecha">Fecha</label>  
            <input className="fecha" type="text" id="Fecha" placeholder="Ingrese Fecha" name="Fecha" />
            
            <label htmlFor="Hora">Hora</label>
            <input className="hora" type="text" id="Hora" placeholder="Ingrese Hora" name="Hora" />
            
            <label htmlFor="Temp-del-display">Temp del display</label>
            <input className="temp-del-display" type="text" id="Temp-del-display" placeholder="Ingrese Temp del display" name="Temp-del-display" />
            
            <label htmlFor="Fecha">Fecha</label>  
            <input className="fecha" type="text" id="Fecha" placeholder="Ingrese Fecha" name="Fecha" />
            
            <label htmlFor="Hora">Hora</label>
            <input className="hora" type="text" id="Hora" placeholder="Ingrese Hora" name="Hora" />
            
            <label htmlFor="Temp-del-display">Temp del display</label>
            <input className="temp-del-display" type="text" id="Temp-del-display" placeholder="Ingrese Temp del display" name="Temp-del-display" />
            
            <label htmlFor="Fecha">Fecha</label>  
            <input className="fecha" type="text" id="Fecha" placeholder="Ingrese Fecha" name="Fecha" />
            
            <label htmlFor="Hora">Hora</label>
            <input className="hora" type="text" id="Hora" placeholder="Ingrese Hora" name="Hora" />
            
            <label htmlFor="Temp-del-display">Temp del display</label>
            <input className="temp-del-display" type="text" id="Temp-del-display" placeholder="Ingrese Temp del display" name="Temp-del-display" />

            <label htmlFor="Fecha">Fecha</label>  
            <input className="fecha" type="text" id="Fecha" placeholder="Ingrese Fecha" name="Fecha" />
            
            <label htmlFor="Hora">Hora</label>
            <input className="hora" type="text" id="Hora" placeholder="Ingrese Hora" name="Hora" />
            
            <label htmlFor="Temp-del-display">Temp del display</label>
            <input className="temp-del-display" type="text" id="Temp-del-display" placeholder="Ingrese Temp del display" name="Temp-del-display" />
            
            <label htmlFor="Fecha">Fecha</label>  
            <input className="fecha" type="text" id="Fecha" placeholder="Ingrese Fecha" name="Fecha" />
            
            <label htmlFor="Hora">Hora</label>
            <input className="hora" type="text" id="Hora" placeholder="Ingrese Hora" name="Hora" />
            
            <label htmlFor="Temp-del-display">Temp del display</label>
            <input className="temp-del-display" type="text" id="Temp-del-display" placeholder="Ingrese Temp del display" name="Temp-del-display" />
            
            <label htmlFor="Fecha">Fecha</label>  
            <input className="fecha" type="text" id="Fecha" placeholder="Ingrese Fecha" name="Fecha" />
            
            <label htmlFor="Hora">Hora</label>
            <input className="hora" type="text" id="Hora" placeholder="Ingrese Hora" name="Hora" />
            
            <label htmlFor="Temp-del-display">Temp del display</label>
            <input className="temp-del-display" type="text" id="Temp-del-display" placeholder="Ingrese Temp del display" name="Temp-del-display" />
            <label htmlFor="Fecha">Fecha</label>  
            <input className="fecha" type="text" id="Fecha" placeholder="Ingrese Fecha" name="Fecha" />
            
            <label htmlFor="Hora">Hora</label>
            <input className="hora" type="text" id="Hora" placeholder="Ingrese Hora" name="Hora" />
            
            <label htmlFor="Temp-del-display">Temp del display</label>
            <input className="temp-del-display" type="text" id="Temp-del-display" placeholder="Ingrese Temp del display" name="Temp-del-display" />
            
            </form>
                <button>enviar</button>

        </div>
    )
}