import React from 'react';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import Nav from '../components/Nav/Nav';
import { CertificadoOp9 } from '../formscert/certificadoOP/certifiacadoOp9/CertificadoOp9';
import CertificadoOp1 from '../formscert/certificadoOP/certificadoOP1/CertificadoOp1';
import { CertificadoOp10 } from '../formscert/certificadoOP/certificadoOp10/CertificadoOp10';
import { CertificadoOp11 } from '../formscert/certificadoOP/certificadoOp11/CertificadoOp11';
import { CertificadoOp12 } from '../formscert/certificadoOP/certificadoOp12/CertificadoOp12';
import CertificadoOp2 from '../formscert/certificadoOP/certificadoOP2/CertificadoOP2';
import CertificadoOp3 from '../formscert/certificadoOP/certificadoOp3/CertificadoOp3';
import CertificadoOp4 from '../formscert/certificadoOP/certificadoOp4/CertificadoOp4';
import { CertificadoOP5 } from '../formscert/certificadoOP/certificadoOp5/CertificadoOP5';
import { CertificadoOP6 } from '../formscert/certificadoOP/certificadoOp6/CertificadoOP6';
import { CertificadoOP7 } from '../formscert/certificadoOP/certificadoOP7/CertificadoOP7';
import { CertificadoOp8 } from '../formscert/certificadoOP/certificadoOP8/CertificadoOp8';



export default function CertificadoOperacional () {
    return(
        <section>
            <div className="container-certificadoOP">
                <div>
                <Header pagina="pagina 1 de 16" title="INFORME CALIFICACIÓN OPERACIONAL DE EQUIPOS" />
                <Nav serie="IF-CD-01" version="Versión:02" fecha="Noviembre 2018" />
                </div>
                <CertificadoOp1 />
                <Footer informe="000000" pagina="pagina 1" />
            </div>
            <div className="container-certificadoOP">
                <div>
                <Header pagina="pagina 2 de 16" title="INFORME CALIFICACIÓN OPERACIONAL DE EQUIPOS" />
                <Nav serie="IF-CD-01" version="Versión:02" fecha="Noviembre 2018" />
                </div>
                <CertificadoOp2 />
                <Footer informe="000000" pagina="pagina 2" />
            </div>
            <div className="container-certificadoOP">
                <div>
                <Header pagina="pagina 3 de 16" title="INFORME CALIFICACIÓN OPERACIONAL DE EQUIPOS" />
                <Nav serie="IF-CD-01" version="Versión:02" fecha="Noviembre 2018" />
                </div>
                <CertificadoOp3 />
                <Footer informe="000000" pagina="pagina 3" />
            </div>
            <div className="container-certificadoOP">
                <div>
                <Header pagina="pagina 4 de 16" title="INFORME CALIFICACIÓN OPERACIONAL DE EQUIPOS" />
                <Nav serie="IF-CD-01" version="Versión:02" fecha="Noviembre 2018" />
                </div>
                <CertificadoOp4 />
                <Footer informe="000000" pagina="pagina 4" />
            </div>
            <div className="container-certificadoOP">
                <div>
                <Header pagina="pagina 5 de 16" title="INFORME CALIFICACIÓN OPERACIONAL DE EQUIPOS" />
                <Nav serie="IF-CD-01" version="Versión:02" fecha="Noviembre 2018" />
                </div>
                <CertificadoOP5 />
                <Footer informe="000000" pagina="pagina 5" />
            </div>
            <div className="container-certificadoOP">
                <div>
                <Header pagina="pagina 6 de 16" title="INFORME CALIFICACIÓN OPERACIONAL DE EQUIPOS" />
                <Nav serie="IF-CD-01" version="Versión:02" fecha="Noviembre 2018" />
                </div>
                <CertificadoOP6 />
                <Footer informe="000000" pagina="pagina 6" />
            </div>
            <div className="container-certificadoOP">
                <div>
                <Header pagina="pagina 7 de 16" title="INFORME CALIFICACIÓN OPERACIONAL DE EQUIPOS" />
                <Nav serie="IF-CD-01" version="Versión:02" fecha="Noviembre 2018" />
                </div>
                <CertificadoOP7 />
                <Footer informe="000000" pagina="pagina 7" />
            </div>
            <div className="container-certificadoOP">
                <div>
                <Header pagina="pagina 8 de 16" title="INFORME CALIFICACIÓN OPERACIONAL DE EQUIPOS" />
                <Nav serie="IF-CD-01" version="Versión:02" fecha="Noviembre 2018" />
                </div>
                <CertificadoOp8 />
                <Footer informe="000000" pagina="pagina 8" />
            </div>
            <div className="container-certificadoOP">
                <div>
                <Header pagina="pagina 9 de 16" title="INFORME CALIFICACIÓN OPERACIONAL DE EQUIPOS" />
                <Nav serie="IF-CD-01" version="Versión:02" fecha="Noviembre 2018" />
                </div>
                <CertificadoOp9 />
                <Footer informe="000000" pagina="pagina 9" />
            </div>
            <div className="container-certificadoOP">
                <div>
                <Header pagina="pagina 10 de 16" title="INFORME CALIFICACIÓN OPERACIONAL DE EQUIPOS" />
                <Nav serie="IF-CD-01" version="Versión:02" fecha="Noviembre 2018" />
                </div>
                <CertificadoOp10 />
                <Footer informe="000000" pagina="pagina 10" />
            </div>
            <div className="container-certificadoOP">
                <div>
                <Header pagina="pagina 11 de 16" title="INFORME CALIFICACIÓN OPERACIONAL DE EQUIPOS" />
                <Nav serie="IF-CD-01" version="Versión:02" fecha="Noviembre 2018" />
                </div>
                <CertificadoOp11 />
                <Footer informe="000000" pagina="pagina 11" />
            </div>
            <div className="container-certificadoOP">
                <div>
                <Header pagina="pagina 12 de 16" title="INFORME CALIFICACIÓN OPERACIONAL DE EQUIPOS" />
                <Nav serie="IF-CD-01" version="Versión:02" fecha="Noviembre 2018" />
                </div>
                <CertificadoOp12 />
                <Footer informe="000000" pagina="pagina 12" />
            </div>
            <div className="container-certificadoOP">
                <div>
                <Header pagina="pagina 13 de 16" title="INFORME CALIFICACIÓN OPERACIONAL DE EQUIPOS" />
                <Nav serie="IF-CD-01" version="Versión:02" fecha="Noviembre 2018" />
                </div>
                <Footer informe="000000" pagina="pagina 13" />
            </div>
            <div className="container-certificadoOP">
                <div>
                <Header pagina="pagina 14 de 16" title="INFORME CALIFICACIÓN OPERACIONAL DE EQUIPOS" />
                <Nav serie="IF-CD-01" version="Versión:02" fecha="Noviembre 2018" />
                </div>
                <Footer informe="000000" pagina="pagina 14" />
            </div>
            <div className="container-certificadoOP">
                <div>
                <Header pagina="pagina 15 de 16" title="INFORME CALIFICACIÓN OPERACIONAL DE EQUIPOS" />
                <Nav serie="IF-CD-01" version="Versión:02" fecha="Noviembre 2018" />
                </div>
                <Footer informe="000000" pagina="pagina 15" />
            </div>
            <div className="container-certificadoOP">
                <div>
                <Header pagina="pagina 16 de 16" title="INFORME CALIFICACIÓN OPERACIONAL DE EQUIPOS" />
                <Nav serie="IF-CD-01" version="Versión:02" fecha="Noviembre 2018" />
                </div>
                <Footer informe="000000" pagina="pagina 16" />
            </div>
        </section>
    )
}