import './CertificadoOp3.scss';
import React from 'react';


export default function CertificadoOp3 () {
    return(
        <section className="container-certOP">
            <h3>CONTENIDO</h3>
            <div className="container-contenico">
                <ol>
                    <li>Objetivo</li>
                    <li>Alcance</li>
                    <li>Identificacion de equipos de medicion</li>
                    <li>Datos del equipo
                        <ol>
                            <li>Identificacion del equipo</li>
                            <li>Informacion Tecnica</li>
                            <li>Uso Previsto</li>
                        </ol>
                    </li>
                    <li>Descripcion de las pruebas
                        <ol>
                            <li>Criterios de aceptación</li>
                            <li>Verificación del uso previsto</li>
                            <li>Calificación de la distribución de temperatura</li>
                            <li>Determinación puntal de temperatura (Puntos fríos/ calientes)</li>
                            <li>Pruebas de Fallas (hold over)</li>
                            <li>Calificación del control del equipo</li>
                            <li>Pruebas de alarmas</li>
                        </ol>
                    </li>
                    <li>Resultados de las pruebas
                        <ol>
                            <li>Verificación del uso previsto</li>
                            <li>Calificación de la distribución de temperatura</li>
                            <li>Determinación puntual de temperatura (Puntos fríos/ calientes)</li>
                            <li>Pruebas de Fallas (hold over)</li>
                            <li>Calificación del control del equipo
                                <ol>
                                    <li>Verificación de la programación del control</li>
                                    <li>Verificación de la programación Ciclos Defrost</li>
                                    <li>Verificación de los sensores del equipo</li>
                                </ol>
                            </li>
                            <li>Pruebas de alarmas</li>
                        </ol>
                    </li>
                    <li>Conclusiones</li>
                    <li>Desviaciones y discrepancias</li>
                    <li>Criterios de recalificación</li>
                    <li>Anexos </li>
                </ol>
            </div>
        </section>
    )
}
