import "./FalloEnergia.scss";
import React from "react";


export default function FalloEnergia () {
    return(
        <div>
            <div className="recomendaciones">
                <span>RECOMENDACIONES </span>
                <span>Verifique si el control tiene batería de respaldo para poder tomar los datos de temperatura cuando el equipo este apagado, en caso contrario coloque un termómetro externo   previamente y déjelo atemperar mínimo 5 minutos antes de empezar la prueba</span>
            </div>
            <form className="form-falloenergia" name="formulario" method="post" action="/">
            <span className="container-fechahora">
            <label htmlFor="FechaHora">Escriba la fecha, hora y temperatura del equipo y apáguelo</label>

            <input className="fechahora" type="text" id="FechaHora" placeholder="Ingrese Fecha" name="FechaHora" />
            </span>
            <input className="fechahora" type="text" id="FechaHora" placeholder="Ingrese Hora" name="FechaHora" />
            <input className="fechahora" type="text" id="FechaHora" placeholder="Ingrese Temperatura" name="FechaHora" />

            <label htmlFor="Parametro">Espere   a que el equipo se salga de parámetro por temperatura alta y tome nuevamente   la hora y temperatura, máximo tiempo de espera 1 hora desde que se apagó el equipo</label>
            <input className="parametro" type="text" id="Parametro" placeholder="Ingrese Hora" name="Parametro" />
            <input className="parametro" type="text" id="Parametro" placeholder="Ingrese Temperatura" name="Parametro" />
            
            <label htmlFor="FalloEquipo">Si el equipo duro la hora y no se salió de parámetro tome la hora y la temperatura y prenda el equipo de lo contrario espere 15 minutos más  después que se salga de parámetros  y tome la hora  y la temperatura y prenda el equipo</label>
            <input className="falloequipo" type="text" id="FalloEquipo" placeholder="Ingrese Hora" name="FalloEquipo" />
            <input className="falloequipo" type="text" id="FalloEquipo" placeholder="Ingrese Temperatura" name="FalloEquipo" />
            
            <label htmlFor="ReoveryTem">Espere que se recupere la temperatura y escriba la   hora.</label>
            <input className="reoverytem" type="text" id="ReoveryTem" placeholder="Ingrese Hora" name="ReoveryTem" />
            <input className="reoverytem" type="text" id="ReoveryTem" placeholder="Ingrese Temperatura" name="ReoveryTem" />

            
            </form>
        </div>
    )
}
