import "./PruebaData.scss";
import React from 'react';


export default function PruebaData (){
    return(
        <div className="container-data">
            <span className="title-data">
                <h3>PRUEBAS DE ALARMAS </h3>
            </span>
            <span className="info-data">
                <form className="form-registro-PruebaData" name="formulario" method="post" action="/">
                    <p>Puerta abierta: abra la puerta e identifique si  se activa   alguna alarma  (es posible que tenga un retardo)</p>
                    
                    <label htmlFor="PruebaData">Audible</label>
                    <input className="pruebadata" type="checkbox" id="PruebaData" name="PruebaData" />
                    
                    <label htmlFor="PruebaData">Visual</label>
                    <input className="pruebadata" type="checkbox" id="PruebaData" name="PruebaData" />
                    
                    
                    <p>Temp alta: toque el sensor con la mano  y verifique la activación de la  alarma  o mantenga la puerta abierta hasta que se salga de rango</p>
                    <label htmlFor="PruebaData">Audible</label>
                    <input className="pruebadata" type="checkbox" id="PruebaData" name="PruebaData" />
                    
                    <label htmlFor="PruebaData">Visual</label>
                    <input className="pruebadata" type="checkbox" id="PruebaData" name="PruebaData" />
                    
                    <p>Temp baja  en refrigeración:  coloque una pila congelada lo mas  cerca al sensor  del equipo</p>
                    <label htmlFor="PruebaData">Audible</label>
                    <input className="pruebadata" type="checkbox" id="PruebaData" name="PruebaData" />
                    
                    <label htmlFor="PruebaData">Visual</label>
                    <input className="pruebadata" type="checkbox" id="PruebaData" name="PruebaData" />
                    
                    <span className="data-otra">
                    <label htmlFor="">Otra:</label>
                    <input type="text" name="" id="" />
                    </span>
                    <label htmlFor="PruebaData">Audible</label>
                    <input className="pruebadata" type="checkbox" id="PruebaData" name="PruebaData" />
                    
                    <label htmlFor="PruebaData">Visual</label>
                    <input className="pruebadata" type="checkbox" id="PruebaData" name="PruebaData" />
                
                </form>
            </span>
        </div>
    )
}