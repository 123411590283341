import "./CertificadoOP7.scss";
import React from "react";

export const CertificadoOP7 = () => {
  return (
    <div className="container56">
      <dl>
        <dt></dt>
        <dd>
          <dl>
            <dt>5.6. Calificación del control del equipo:</dt>
            <dd>
              El objetivo de esta prueba es verificar que el control del equipo
              está manteniendo efectivamente la temperatura de programación para
              el equipo en prueba y que la temperatura reflejada en el display
              del control corresponde a la temperatura interna del equipo.
            </dd>
            <dd>
              Se ubica un sensor de evaluación junto al sensor del control del
              equipo y se determinan periodos estables de temperatura para
              evaluar la operación del control. En caso de que el sensor del
              control no pueda ser ubicado, se evalúa su operación con todos los
              sensores de evaluación ubicados al interior del equipo.
            </dd>
            <dd>
              Se comparan las lecturas de temperatura mostradas en el display
              del control del equipo, con las arrojadas por los sensores de
              evaluación en los mismos momentos específicos de tiempo.
            </dd>
            <dd>
              Se reportan las temperaturas máximas, mínima y promedio de
              operación durante los periodos estables del equipo, comparando las
              mediciones arrojadas por los sensores de evaluación contra las
              especificaciones del fabricante y los criterios de aceptación del
              cliente.
            </dd>
            <dd>
              Se realiza la verificación de la programación del ciclo de defrost
              (ciclo de descongelamiento automático), comparando tiempos de
              activación y de recuperación, efectividad del ciclo.
            </dd>
            <dt>5.7. Prueba de alarmas:</dt>
            <dd>
              Se realiza esta prueba para verificar la respuesta de las alarmas
              del control del equipo (visuales y/o sonoras, según aplique al
              modelo del control), cuando se presentan las condiciones
              programadas para su activación.
            </dd>
            <dd>
              Se ubica un sensor de evaluación cerca al sensor del control del
              equipo, se simulan las condiciones de alarma programadas en el
              equipo (según aplique de acuerdo al control del equipo), se
              verifica la activación de las alarmas y su desactivación, una vez
              restablecidas las condiciones de operación normal del equipo.
            </dd>
            <dd>
              Se anexa una tabla indicando las alarmas aplicables para ser
              evaluadas, de acuerdo al tipo de control del equipo y los
              resultados de las pruebas.
            </dd>
          </dl>
        </dd>
      </dl>
    </div>
  );
};
