import '../assets/styles/styles.scss'
import React from 'react';
import Header from '../components/Header/Header';
import Nav from '../components/Nav/Nav';
import RegistroCliente from '../forms/registrocliente/RegistroCliente';
//import { GetDataLogger } from '../data/getData';
// import { PostData } from '../data/PostData';

 import NavBlue from '../components/Nav/Nav-blue';
 import NavGreen from '../components/Nav/Nav-green';
 import EncuestaCliente from '../forms/encuestaCliente/EcuestaCliente';
 import InfoEquipos from '../forms/informacion del equipo/InfoEquipos';
 import InfoTecnica from '../forms/infotecnica/InfoTecnica';
 import TomaDatos from '../forms/tomadatos/TomaDatos';
 import PruebaData from '../forms/pruebadata/PruebaData';
 import NavRed from '../components/Nav/Nav-red';
 import VerificarDatos from '../forms/verificardatos/VerificarDatos';
 import PruebaDesempeño from '../forms/pruebasdesempeño/PruebaDesempeño';
 import FalloEnergia from '../forms/falloenergia/FalloEnergia';


export default function PageRegistro1() {
    return (<div>
                {/* <PostData /> */}
                {/* <GetDataLogger /> */}
                <Header pagina="Página 1 de 3" title="REGISTRO DE DATOS CALIFICACIÓN  DE INSTALACIÓN" />
                <Nav serie="R-DCI-01" version="Version 01" fecha="Julio 2018" />
                <RegistroCliente />
                <NavBlue title="ENCUESTA INICIAL  AL CLIENTE" />
                <NavGreen title="ASPECTOS GENERALES"/>
                <EncuestaCliente />
                <NavBlue title="INFORMACIÓN DEL EQUIPO " />
                <InfoEquipos />
                <NavBlue title="INFORMACIÓN TÉCNICA" />
                <InfoTecnica/>
                <Header pagina="pagina 2 de 3" title="REGISTRO DE DATOS CALIFICACIÓN  DE INSTALACIÓN" />
                <Nav serie="R-DCI-01" version="Version 01" fecha="Julio 2018" />
                <NavBlue title="TOMA DE DATOS" />
                <TomaDatos />
                <NavGreen  title="ESPERE APROXIMADAMENTE 15 MINUTOS A QUE SE ATEMPEREN LOS DATALOGGER PARA CONTINUAR CON LAS PRUEBAS " />
                <PruebaData />
                <NavRed tittle="Toma de datos para verificar el valor real del sensor del equipo: se debe hacer aleatorio durante el proceso de calificación, no importa si el equipo está o no dentro de las temperaturas de trabajo, no se debe realizar tan pronto de colocan los sensores. S y con intervalos mayores a 5 minutos cada toma" />
                <VerificarDatos />
                <Header pagina="pagina 3 de 3" title="REGISTRO DE DATOS CALIFICACIÓN  DE INSTALACIÓN" />
                <Nav serie="R-DCI-01" version="Version 01" fecha="Julio 2018" />
                <NavBlue title="PRUEBAS DE FALLO DE ENERGÍA (HOLD OVER )" />
                <FalloEnergia />
                <NavGreen title="REALICE ESTAS PRUEBAS SOLO PARA CALIFICACIONES DE DESEMPEÑO" />
                <PruebaDesempeño />
            </div>
    );
};