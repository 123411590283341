import './Footer.scss';
import React from 'react';




export default function Footer ( props ) {
    return(
            <div className="container-footer">
                <hr />
                <span className="container-props-footer">
                    <p>informe N° { props.informe }</p>
                    <p>{ props.pagina }</p>
                </span>
            </div>
    )
}



