import React from "react";
import "./CertificadoOP5.scss";

export const CertificadoOP5 = () => {
  return (
    <div>
      <div>
        <h3>4. DATOS DEL EQUIPO EN PRUEBA</h3>
        <h4>4.1. Identificación</h4>
        <form
          className="form-InfoFichaTecnica41"
          name="formularioInfoFichaTecnica41"
          method="get"
          action="/"
        >
          <span>
            <h5 htmlFor="Tipo4">Tipo</h5>
            <input
              className="Tipo4"
              type="text"
              id="Tipo4"
              placeholder="Refrigerador"
              name="Tipo4"
            />
          </span>
          <span>
            <h5 htmlFor="Fabricante4">Fabricante</h5>
            <input
              className="Fabricante4"
              type="text"
              id="Fabricante4"
              placeholder="Biored"
              name="Fabricante4"
            />
          </span>
          <span>
            <h5 htmlFor="Modelo4">Modelo</h5>
            <input
              className="Modelo4"
              type="text"
              id="Modelo4"
              placeholder="BIO-RVPG-15AI"
              name="Modelo4"
            />
          </span>
          <span>
            <h5 htmlFor="Serie4">Serie</h5>
            <input
              className="Serie4"
              type="text"
              id="Serie4"
              placeholder="BIO-R2000448"
              name="Serie4"
            />
          </span>
          <span>
            <h5 htmlFor="Inventario4">Inventario</h5>
            <input
              className="Inventario4"
              type="text"
              id="Inventario4"
              placeholder="No registra"
              name="Inventario4"
            />
          </span>
          <span>
            <h5 htmlFor="Ubicacion4">Ubicación</h5>
            <input
              className="Ubicacion4"
              type="text"
              id="Ubicacion4"
              placeholder="Laboratorio de Calidad de Biored"
              name="Ubicacion4"
            />
          </span>
        </form>
      </div>

      <div>
        <h4>4.2. Información Técnica</h4>
        <section className="container42">
          <form
            className="form-FichaTecnica42"
            name="formularioInfoFichaTecnica42"
            method="get"
            action="/"
          >
            <span>
              <h5 htmlFor="Capacacidad42">Capacidad</h5>
              <input
                className="Capacacidad42"
                type="text"
                id="Capacacidad42"
                placeholder="501L"
                name="Capacacidad42"
              />
            </span>
            <span>
              <h5 htmlFor="Control42">Control</h5>
              <input
                className="Control42"
                type="text"
                id="Control42"
                placeholder="BioTemp"
                name="Control42"
              />
            </span>
          </form>

          <div className="container-sensores42">
            <h4>Sensores</h4>
            <form
              className="form-Sensores42"
              name="formularioSensores42"
              method="get"
              action="/"
            >
              <span>
                <h5 htmlFor="Camara42">Cámara</h5>
                <input
                  className="Camara42"
                  type="checkbox"
                  id="Camara42"
                  name="Camara42"
                />
              </span>
              <span>
                <h5 htmlFor="Evaporador42">Evaporador</h5>
                <input
                  className="Evaporador42"
                  type="text"
                  id="Evaporador42"
                  placeholder="No Visible"
                  name="Evaporador42"
                />
              </span>
              <span>
                <h5 htmlFor="Otro42">Otro</h5>
                <input
                  className="Otro42"
                  type="text"
                  id="Otro42"
                  placeholder=""
                  name="Otro42"
                />
              </span>
            </form>
          </div>

          <div className="container-Alarmas42">
            <h4>Alarmas</h4>
            <form
              className="form-Alarmas42"
              name="formularioAlarmas42"
              method="get"
              action="/"
            >
              <span>
                <h5 htmlFor="TempAlta42">Temp. Alta</h5>
                <input
                  className="TempAlta42"
                  type="checkbox"
                  id="TempAlta42"
                  name="TempAlta42"
                />
              </span>
              <span>
                <h5 htmlFor="TempBaja42">Temp. Baja</h5>
                <input
                  className="TempBaja42"
                  type="checkbox"
                  id="TempBaja42"
                  name="Tempbaja42"
                />
              </span>
              <span>
                <h5 htmlFor="PuertaAbierta42">Puerta Abierta</h5>
                <input
                  className="PuertaAbierta42"
                  type="checkbox"
                  id="PuertaAbierta42"
                  name="PuertaAbierta42"
                />
              </span>
              <span>
                <h5 htmlFor="FallaDeEnergia42">Falla de Energía</h5>
                <input
                  className="FallaDeEnergia42"
                  type="checkbox"
                  id="FallaDeEnergia42"
                  name="FallaDeEnergia42"
                />
              </span>
              <span>
                <h5 htmlFor="FallaDeSensor42">Falla De Sensor</h5>
                <input
                  className="FallaDeSensor42"
                  type="checkbox"
                  id="FallaDeSensor42"
                  name="FallaDeSensor42"
                />
              </span>
              <span>
                <h5 htmlFor="CargaDeBateria42">Carga de bateria</h5>
                <input
                  className="CargaDeBateria42"
                  type="checkbox"
                  id="CargaDeBateria42"
                  name="CargaDeBateria42"
                />
              </span>
            </form>
          </div>
        </section>
        <p>
          CAPACIDAD EN EL REGISTRO LA PIDEN EN PIES Y ACA EN LITROS TU HACES LA
          CONVERSION
        </p>
      </div>
      <h4>4.3 Uso Previsto</h4>
      <div className="container43">
        <form className="form-43" name="formulario-43" method="get" action="/">
          <span>
            <h5>Temperatura Esperada</h5>
            <input
              className="temperatura-esperada43"
              type="text"
              id="TemEsperada43"
              placeholder="1°C a 10°C"
              name="TemEsperada43"
            />
          </span>
          <span>
            <h5>Set Point</h5>
            <input
              className="set-point43"
              type="text"
              id="SetPoint43"
              placeholder="3°C"
              name="SetPoint"
            />
          </span>
        </form>
        <div className="progalarmas43">
          <h4>Programación de Alarmas</h4>
          <form
            className="form-alarmas43"
            name="formulario-alarmas43"
            method="get"
            action="/"
          >
            <span className="inputs43">
              <label htmlFor="TemAlta43">Temp Alta</label>
              <input
                className="temperatura-alta43"
                type="text"
                id="TemAlta43"
                placeholder="10°C"
                name="TemAlta43"
              />
            </span>
            <span className="inputs43">
              <label htmlFor="TempBaja43">Tem Baja</label>
              <input
                className="temperatura-baja43"
                type="text"
                id="TemBaja43"
                placeholder="10°C"
                name="TemBaja43"
              />
            </span>
            <span className="inputs43">
              <label htmlFor="PuertaAbiera43">Puerta Abierta</label>
              <input
                className="puerta-abierta43"
                type="checkbox"
                id="PuertaAbierta43"
                name="PuertaAbierta43"
              />
            </span>
            <span className="inputs43">
              <label htmlFor="FallaEnergia43">Falla de Energia</label>
              <input
                className="fallaenergia43"
                type="checkbox"
                id="FallaEnergia43"
                name="FallaEnergia43"
              />
            </span>
            <span className="inputs43">
              <label htmlFor="FallaSensor43">Falla Sensor</label>
              <input
                className="fallasensor43"
                type="checkbox"
                id="FallaSensor43"
                name="FallaSensor43"
              />
            </span>
            <span className="inputs43">
              <label htmlFor="CargaBateria43">Carga de Bateria</label>
              <input
                className="cagabateria43"
                type="checkbox"
                id="CargaBateria43"
                name="CargaBateria43"
              />
            </span>
          </form>
        </div>
      </div>
    </div>
  );
};
