import './TomaDatos.scss';
import React from 'react';
import pos1 from '../../assets/imgs/pos1.png';
import pos2 from '../../assets/imgs/pos2.png';
import pos3 from '../../assets/imgs/pos3.png';
import pos5 from '../../assets/imgs/pos5.png';

export default function TomaDatos (){
    return(
        <section>
        <div className="containter-TomaDatos">
            <div>
                <h3>RECOMENDACIONES</h3>
            </div>
            <form className="form-registro-TomaDatos" name="formulario" method="post" action="/">
                <input className="verifique-sensor" type="checkbox" id="Verifique-sensor" placeholder="Verifique-sensor" name="Verifique-sensor" />
                <label htmlFor="TomaDatos">Verifique que el sensor  del equipo  se encuentre libre, no este pegado directo a alguna pared,  muy cerca del evaporador o  directo a  los ventiladores</label>

                <input className="6-datalogger" type="checkbox" id="6-Datalogger" placeholder="6-Datalogger" name="6-Datalogger" />
                <label htmlFor="6-Datalogger">Utilice 6 Datalogger para equipos hasta 12 pies y 12 Datalogger para equipos de mayor capacidad distribúyalos tal como esta la gráfica</label>
                
                <input className="datalogger-mas-cercano" type="checkbox" id="Datalogger-mas-cercano" placeholder="Datalogger-mas-cercano" name="Datalogger-mas-cercano" />
                <label htmlFor="Datalogger-mas-cercano">Marque con una x el Datalogger  más cercano al sensor del equipo  e instale el Datalogger de humedad y temperatura cerca al equipo  y  retirado del aire acondicionado</label>
                
                <input className="datalogger-equipo" type="checkbox" id="Datalogger-equipo" placeholder="Datalogger-equipo" name="Datalogger-equipo" />
                <label htmlFor="Datalogger-equipo">Ubique los Datalogger  dentro del equipo y realice las pruebas de alarmas</label>
            </form>
        </div>
        <div className="container-tomadato1">
        <h3>PREVIO A LA TOMA DE DATOS VERIFIQUE CON UN TERMOMETRO EXTERNO  QUE LA TEMPERATURA DEL CONTROL  ESTE ACORDE A LO REAL, LA DIFERENCIA NO DEDE SER +/- 1  GRADO</h3>
            <form className="form-registro-TomaDatos1" name="formulario" method="post" action="/">
                <label htmlFor="TomaDatos1">OBSERVACIONES DE TOMA DE DATOS </label>
                <input className="observacionestomadatos" type="text" id="TomaDatos1" placeholder="Observaciones de toma de datos" name="TomaDatos1" />
            </form>
        </div>
        <div>
        <form className="form-registro-Date" name="formulario" method="post" action="/">
            <label htmlFor="Fecha-Inicio-Registro">FECHA INICIO DEL   REGISTRO</label>
            <input className="fecha-inicio-registro" type="text" id="Fecha-Inicio-Registro" placeholder="Fecha Inicio del Registro" name="Fecha-Inicio-Registro" />

            <label htmlFor="Hora-Inicio-Registro">HORA INICIO DEL REGISTRO </label>
            <input className="hora-inicio-registro" type="text" id="Hora-Inicio-Registro" placeholder="Hora Inicio del Registro" name="Hora-Inicio-Registro" />
            
            <label htmlFor="Fecha-Fin-Registro">FECHA FIN DEL REGISTRO</label>
            <input className="fecha-fin-registro" type="text" id="Fecha-Fin-Registro" placeholder="Fecha Fin del Registro" name="Fecha-Fin-Registro" />
            
            <label htmlFor="Hora-Fin-Registro">HORA FIN DE REGISTRO</label>
            <input className="hora-fin-registro" type="text" id="Hora-Fin-Registro" placeholder="Hora Fin del Registro" name="Hora-Fin-Registro" />
            
            </form>
        </div>
        <div>
        <form className="form-registro-Producto" name="formulario" method="post" action="/">
            <label htmlFor="Producto-Almacenado">PRODUCTO ALMACENADO </label>
            <input className="producto almacenado" type="text" id="Producto-Almacenado" placeholder="Producto Almacenado" name="Producto-Almacenado" />

            <label htmlFor="Rangos-Permitidos">CRITERIO DE ACEPTACIÓN: RANGOS DE TEMPERATURA PERMITIDOS</label>
            <input className="rangos-permitidos" type="text" id="Rangos-Permitidos" placeholder="Rangos Permitidos" name="Rangos-Permitidos" />           
            </form>
        </div>
        <div className="container-datos-img">
        <div>
        <h3>UBICACIÓN DE SENSORES</h3>
        <form className="form-registro-Datalogers" name="formulario" method="post" action="https://calificaciones.bioredingenieria.com/api/datalogger">
        <h4>Cant</h4>
        <h3>Series</h3>
      
            <label htmlFor="Serial1">1. </label>
            <input className="serial1" type="text" id="Serial1" placeholder="Numero Serial" name="data_serial" />

            <label htmlFor="Serial2">2.</label>
            <input className="serial2" type="text" id="Serial2" placeholder="Numero Serial" name="Serial2" />           
           
            <label htmlFor="Serial3">3.</label>
            <input className="serial3" type="text" id="Serial3" placeholder="Numero Serial" name="Serial3" />

            <label htmlFor="Serial4">4.</label>
            <input className="serial4" type="text" id="Serial4" placeholder="Numero Serial" name="Serial4" />           
           
            <label htmlFor="Serial5">5.</label>
            <input className="serial5" type="text" id="Serial5" placeholder="Numero Serial" name="Serial5" />

            <label htmlFor="Serial6">6.</label>
            <input className="serial6" type="text" id="Serial6" placeholder="Numero Serial" name="Serial6" />           
           
            <label htmlFor="Serial7">7.</label>
            <input className="serial7" type="text" id="Serial7" placeholder="Numero Serial" name="Serial7" />

            <label htmlFor="Serial8">8.</label>
            <input className="serial8" type="text" id="Serial8" placeholder="Numero Serial" name="Serial8" />           
           
            <label htmlFor="Serial9">9.</label>
            <input className="serial9" type="text" id="Serial9" placeholder="Numero Serial" name="Serial9" />

            <label htmlFor="Serial10">10.</label>
            <input className="serial10" type="text" id="Serial10" placeholder="Numero Serial" name="Serial10" />           
           
            <label htmlFor="Serial11">11.</label>
            <input className="serial11" type="text" id="Serial11" placeholder="Numero Serial" name="Serial11" />

            <label htmlFor="Serial12">12.</label>
            <input className="serial12" type="text" id="Serial12" placeholder="Numero Serial" name="Serial12" />           
            
            <label htmlFor="Serial13">13.</label>
            <input className="serial13" type="text" id="Serial13" placeholder="Numero Serial" name="Serial13" />
            <button type="submit" className="btn btn-primary">Guardar</button>
            </form>
        </div>
        <div className="container-imgs">
            <div>
            <h3>UBICACIÓN DE DATALOGGER    EQUIPOS HASTA  12 PIES </h3>
            <span>
            <input className="eqipo-12Pies" type="checkbox" id="Eqipo-12Pies" name="Eqipo-12Pies" />
            <img src={pos1} alt="Equipo hasta 12 pies" />
            <label htmlFor="PorcCarga">Porcentaje de carga</label>
            <input className="porccarga" type="text" id="PorcCarga" placeholder="Porcentaje de Carga" name="PorcCarga" />
            </span>
            </div>
            <div>
            <h3>UBICACIÓN DE DATALOGGER EQUIPOS PEQUEÑOS</h3>
            <span>
            <input className="eqipo-12Pies" type="checkbox" id="Eqipo-12Pies" name="Eqipo-12Pies" />
            <img src={pos2} alt="Equipo hasta 12 pies" />
            <label htmlFor="PorcCarga">Porcentaje de carga</label>
            <input className="porccarga" type="text" id="PorcCarga" placeholder="Porcentaje de Carga" name="PorcCarga" />
            </span>
            </div>
            <div>
            <h3>UBICACIÓN DE DATALOGGER EQUIPOS MAS DE 12 PIES</h3>
            <span>
            <input className="eqipo-12Pies" type="checkbox" id="Eqipo-12Pies" name="Eqipo-12Pies" />
            <img src={pos3} alt="Equipo hasta 12 pies" />
            <label htmlFor="PorcCarga">Porcentaje de carga</label>
            <input className="porccarga" type="text" id="PorcCarga" placeholder="Porcentaje de Carga" name="PorcCarga" />
            </span>
            </div>
            <div>
            <h3>UBICACIÓN DE DATALOGGER EQUIPOS DOS CUERPOS</h3>
            <span>
            <input className="eqipo-12Pies" type="checkbox" id="Eqipo-12Pies" name="Eqipo-12Pies" />
            <img src={pos5} alt="Equipo hasta 12 pies" />
            <label htmlFor="PorcCarga">Porcentaje de carga</label>
            <input className="porccarga" type="text" id="PorcCarga" placeholder="Porcentaje de Carga" name="PorcCarga" />
            </span>
            </div>
        </div>
        </div>
        </section>
    );
};