import "./Home.scss"
import React from 'react';
import Header from '../../components/Header/Header';
import Nav from "../../components/Nav/Nav";
import { Link } from 'react-router-dom';
import SettingsIcon from '@material-ui/icons/Settings';
import SpeedIcon from '@material-ui/icons/Speed';


export default function Certificados() {
    return(
        <div>
            <Header pagina="HomePage" />
            <Nav serie="R-DCI-01" version="Version 1" fecha="Julio 2021" />
            <div className="container-home">
            <h1>ELIGE EL TIPO DE CERTIFICADO</h1>
            <Link to="/CertificadoOP">
            <h2>Certificado Operacional</h2>
            <SettingsIcon />
            </Link>
            <Link to="/CertificadoDP">
            <h2>Certificado de Desempeño</h2>
            <SpeedIcon />
            </Link>
            </div>
        </div>
    )
}