import './CertificadoOp4.scss';
import React from 'react';


export default function CertificadoOp4 () {
    return(
        <section>
            <div>
                <h3>1.OBJETIVO</h3>
                <p>Documentar  los resultados  obtenidos  durante  la calificación  del funcionamiento  de un equipo de refrigeración,  en condiciones de operación normales, por medio de la realización de un monitoreo durante 24 horas con el equipo sin carga, analizando los resultados contra especificaciones del fabricante.</p>
            </div>
            <div>
                <h3>2.ALCANCE</h3>
                <p>Calificar la operación de un refrigerador, con rango de funcionamiento de 1°C y 10°C, que está ubicado en el Laboratorio de calidad de Biored Ingeniería Ltda. , para verificar que se encuentra operando de acuerdo a las especificaciones de cadena de frío requeridas para el almacenamiento de los productos que requieran refrigeración.</p>
            </div>
            <div>
                <h3>3.IDENTIFICACIÓN DE EQUIPOS DE MEDICIÓN</h3>
                <p>A  continuación,  se  describen  las  características  de  los instrumentos  utilizados  para  la  calificación  del  equipo. Los certificados de calibración se anexan al final del informe.</p>
                <div className="container-form-dataloger">
                    <form
                    className="form-Datalogop"
                    name="formulariopruebasOP"
                    method="get"
                    action="/"
                    >
                    <label htmlFor="DataLogOp">Tipo de Sensor</label>
                    <input
                    className="datalogop"
                    type="text"
                    id="DataLogOp"
                    placeholder="Datalogger de Temperatura"
                    name="DataLogOp"
                    />
                    <input
                    className="datalogop"
                    type="text"
                    id="DataLogOp"
                    placeholder="Datalogger de Temperatura"
                    name="DataLogOp"
                    />
                    <input
                    className="datalogop"
                    type="text"
                    id="DataLogOp"
                    placeholder="Datalogger de Temperatura"
                    name="DataLogOp"
                    />
                    <input
                    className="datalogop"
                    type="text"
                    id="DataLogOp"
                    placeholder="Datalogger de Temperatura"
                    name="DataLogOp"
                    />
                    <input
                    className="datalogop"
                    type="text"
                    id="DataLogOp"
                    placeholder="Datalogger de Temperatura"
                    name="DataLogOp"
                    />
                    <input
                    className="datalogop"
                    type="text"
                    id="DataLogOp"
                    placeholder="Datalogger de Temperatura"
                    name="DataLogOp"
                    />
                    <input
                    className="datalogop"
                    type="text"
                    id="DataLogOp"
                    placeholder="Datalogger de Temperatura & Humedad"
                    name="DataLogOp"
                    />
                    </form>
                    <form
                    className="form-Datalogop"
                    name="formulariopruebasOP"
                    method="get"
                    action="/"
                    >
                    <label htmlFor="ModDataLogOp">Modelo</label>
                    <input
                    className="moddatalogop"
                    type="text"
                    id="ModDataLogOp"
                    placeholder="Trix-8"
                    name="ModDataLogOp"
                    />
                    <input
                    className="moddatalogop"
                    type="text"
                    id="ModDataLogOp"
                    placeholder="Trix-8"
                    name="ModDataLogOp"
                    />
                    <input
                    className="moddatalogop"
                    type="text"
                    id="ModDataLogOp"
                    placeholder="Trix-8"
                    name="ModDataLogOp"
                    />
                    <input
                    className="moddatalogop"
                    type="text"
                    id="ModDataLogOp"
                    placeholder="Trix-8"
                    name="ModDataLogOp"
                    />
                    <input
                    className="moddatalogop"
                    type="text"
                    id="ModDataLogOp"
                    placeholder="Trix-8"
                    name="ModDataLogOp"
                    />
                    <input
                    className="moddatalogop"
                    type="text"
                    id="ModDataLogOp"
                    placeholder="Trix-8"
                    name="ModDataLogOp"
                    />
                    <input
                    className="moddatalogop"
                    type="text"
                    id="ModDataLogOp"
                    placeholder="Haxo-8"
                    name="ModDataLogOp"
                    />
                    </form>
                    <form
                    className="form-Datalogop"
                    name="formulariopruebasOP"
                    method="get"
                    action="/"
                    >
                    <label htmlFor="SerieDataLogOp">Serie</label>
                    <input
                    className="seriedatalogop"
                    type="text"
                    id="SerieDataLogOp"
                    placeholder="1000486437"
                    name="SerieDataLogOp"
                    />
                    <input
                    className="seriedatalogop"
                    type="text"
                    id="SerieDataLogOp"
                    placeholder="1000945897"
                    name="SerieDataLogOp"
                    />
                    <input
                    className="seriedatalogop"
                    type="text"
                    id="SerieDataLogOp"
                    placeholder="1000945901"
                    name="SerieDataLogOp"
                    />
                    <input
                    className="seriedatalogop"
                    type="text"
                    id="SerieDataLogOp"
                    placeholder="1000945898"
                    name="SerieDataLogOp"
                    />
                    <input
                    className="seriedatalogop"
                    type="text"
                    id="SerieDataLogOp"
                    placeholder="1000729863"
                    name="SerieDataLogOp"
                    />
                    <input
                    className="seriedatalogop"
                    type="text"
                    id="SerieDataLogOp"
                    placeholder="1020200224"
                    name="SerieDataLogOp"
                    />
                    <input
                    className="seriedatalogop"
                    type="text"
                    id="SerieDataLogOp"
                    placeholder="1010140500"
                    name="SerieDataLogOp"
                    />
                    
                    </form>
                    <form
                    className="form-Datalogop"
                    name="formulariopruebasOP"
                    method="get"
                    action="/"
                    >
                    <label htmlFor="RangoDataLogOp">Rango de Medicion</label>
                    <input
                    className="rangodatalogop"
                    type="text"
                    id="RangoDataLogOp"
                    placeholder="-86°C a 35°C"
                    name="RangoDataLogOp"
                    />
                    <input
                    className="rangodatalogop"
                    type="text"
                    id="RangoDataLogOp"
                    placeholder="-86°C a 35°C"
                    name="RangoDataLogOp"
                    />
                    <input
                    className="rangodatalogop"
                    type="text"
                    id="RangoDataLogOp"
                    placeholder="-86°C a 35°C"
                    name="RangoDataLogOp"
                    />
                    <input
                    className="rangodatalogop"
                    type="text"
                    id="RangoDataLogOp"
                    placeholder="-86°C a 35°C"
                    name="RangoDataLogOp"
                    />
                    <input
                    className="rangodatalogop"
                    type="text"
                    id="RangoDataLogOp"
                    placeholder="-86°C a 35°C"
                    name="RangoDataLogOp"
                    />
                    <input
                    className="rangodatalogop"
                    type="text"
                    id="RangoDataLogOp"
                    placeholder="-86°C a 35°C"
                    name="RangoDataLogOp"
                    />
                    <input
                    className="rangodatalogop"
                    type="text"
                    id="RangoDataLogOp"
                    placeholder="-40°C a 85°C / 0 a"
                    name="RangoDataLogOp"
                    />
                    
                    </form>
                    <form
                    className="form-Datalogop"
                    name="formulariopruebasOP"
                    method="get"
                    action="/"
                    >
                    <label htmlFor="EscDataLogOp">Div de Escala</label>
                    <input
                    className="escdatalogop"
                    type="text"
                    id="EscDataLogOp"
                    placeholder="0,1°C"
                    name="EscDataLogOp"
                    />
                    <input
                    className="escdatalogop"
                    type="text"
                    id="EscDataLogOp"
                    placeholder="0,1°C"
                    name="EscDataLogOp"
                    />
                    <input
                    className="escdatalogop"
                    type="text"
                    id="EscDataLogOp"
                    placeholder="0,1°C"
                    name="EscDataLogOp"
                    />
                    <input
                    className="escdatalogop"
                    type="text"
                    id="EscDataLogOp"
                    placeholder="0,1°C"
                    name="EscDataLogOp"
                    />
                    <input
                    className="escdatalogop"
                    type="text"
                    id="EscDataLogOp"
                    placeholder="0,1°C"
                    name="EscDataLogOp"
                    />
                    <input
                    className="escdatalogop"
                    type="text"
                    id="EscDataLogOp"
                    placeholder="0,1°C"
                    name="EscDataLogOp"
                    />
                    <input
                    className="escdatalogop"
                    type="text"
                    id="EscDataLogOp"
                    placeholder="0,1°C /0,1%HR"
                    name="EscDataLogOp"
                    />
                    </form>
                </div>
            </div>
        </section>
    )
}









