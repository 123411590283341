import './InfoTecnica.scss'
import React from 'react';
import { Link } from 'react-router-dom';

export default function InfoTecnica (){
    return(
        <section>
        <section className="containter-InfoTecnica">
            <div>
            <h3>CONTROLADOR</h3>
                <form className="form-registro-InfoTecnica" name="formulario" method="post" action="/">
                    <label htmlFor="Mecanico">MECÁNICO</label>
                    <input className="mecanico" type="text" id="Mecanico" placeholder="Mecanico" name="Mecanico" />
            
                    <label htmlFor="Digital">DIGITAL</label>
                    <input className="digital" type="text" id="Digital" placeholder="Digital" name="Digital" />
            
                    <label htmlFor="Modelo">MODELO</label>
                    <input className="modelo" type="text" id="Modelo" placeholder="Modelo" name="Modelo" />

                    <label htmlFor="Marca">MARCA</label>
                    <input className="marca" type="text" id="Marca" placeholder="Marca" name="Marca" />

                </form>
            </div>
            <div>
            <h3>SENSORES</h3>
                <form className="form-registro-InfoTecnica" name="formulario" method="post" action="/">
                    <label htmlFor="Evaporador">EVAPORADOR</label>  
                    <input className="evaporador" type="text" id="Evaporador" placeholder="Evaporador" name="Evaporador" />
            
                    <label htmlFor="Camara-interna">CÁMARA INTERNA</label>
                    <input className="camara-interna" type="text" id="Camara-interna" placeholder="Camara-interna" name="Camara-interna" />
            
                    <label htmlFor="Valor-programacion-tem-baja">VALOR PROGRAMACIÓN TEMP BAJA</label>
                    <input className="valor-programacion-tem-baja" type="text" id="Valor-programacion-tem-baja" placeholder="Valor-programacion-tem-baja" name="Valor-programacion-tem-baja" />
            
                    <label htmlFor="Valor-programacion-tem-alta">VALOR PROGRAMACIÓN TEM ALTA</label>
                    <input className="valor-programacion-tem-alta" type="text" id="Valor-programacion-tem-alta" placeholder="Valor-programacion-tem-alta" name="Valor-programacion-tem-alta" />
                </form>
            </div>
            <div>
            <h3>PROGRAMACIÓN  DEL CONTROL</h3>
                <form className="form-registro-InfoTecnica" name="formulario" method="post" action="/">
            
                    <label htmlFor="Set-point">SET POINT</label>
                    <input className="set-point" type="text" id="Set-point" placeholder="Set-point" name="Set-point" />
            
                    <label htmlFor="Diferencial">DIFERENCIAL</label>
                    <input className="diferencial" type="text" id="Diferencial" placeholder="Diferencial" name="Diferencial" />
            
                    <label htmlFor="Tem-de-parada-del-compresor">TEM DE PARADA DEL COMPRESOR</label>
                    <input className="tem-de-parada-del-compresor" type="text" id="Tem-de-parada-del-compresor" placeholder="Tem-de-parada-del-compresor" name="Tem-de-parada-del-compresor" />
            
                    <label htmlFor="Tem-arranque-del-compresor">TEM  ARRANQUE DEL COMPRESOR</label>
                    <input className="tem-arranque-del-compresor" type="text" id="Tem-arranque-del-compresor" placeholder="Tem-arranque-del-compresor" name="Tem-arranque-del-compresor" />
                </form>
            </div>
            <div>
            <h3>CONTROLES EXTERNOS</h3>
                <form className="form-registro-InfoTecnica" name="formulario" method="post" action="/">
            
                    <label htmlFor="Sensor-de-puerta">SENSOR DE PUERTA</label>  
                    <input className="sensor-de-puerta" type="text" id="Sensor-de-puerta" placeholder="Sensor-de-puerta" name="Equipo" />
            
                    <label htmlFor="No-de-ventiladores-en-el-evaporador">No DE VENTILADORES EN EL EVAPORADOR</label>
                    <input className="no-de-ventiladores-en-el-evaporador" type="text" id="No-de-ventiladores-en-el-evaporador" placeholder="No-de-ventiladores-en-el-evaporador" name="No-de-ventiladores-en-el-evaporador" />
            
                    <label htmlFor="Monitoreo-en-tiempo-real">MONITOREO EN TIEMPO REAL</label>
                    <input className="monitoreo-en-tiempo-real" type="text" id="Monitoreo-en-tiempo-real" placeholder="Monitoreo-en-tiempo-real" name="Monitoreo-en-tiempo-real" />
                </form>
            </div>
           
            </section>
            <form className="form-registro-InfoTecnica1" name="formulario" method="post" action="/">
            
            <label htmlFor="Observaciones-tecnicas">OBSERVACIONES TÉCNICAS</label>
            <input className="observaciones-tecnicas" type="text" id="Observaciones-tecnicas" placeholder="Observaciones-tecnicas" name="Observaciones-tecnicas" />
             </form> 
                <Link to="/PageRegistro2">
                    <button>enviar</button>
                </Link>
        </section>
        
    );
};
