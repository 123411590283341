import React from 'react';
import Header from '../components/Header/Header';
import Nav from '../components/Nav/Nav';




export default function CertificadoDesempeño () {
    return(
        <div>
            <Header pagina="pagina 1 de 17" title="INFORME CALIFICACIÓN DE DESEMPEÑO DE EQUIPOS" />
            <Nav serie="IF-CD-01" version="Version 04" fecha="Marzo 2020" />

           
        </div>
    )
}