import "./CertificadoOp9.scss";

import React from "react";

export const CertificadoOp9 = () => {
  return (
    <div className="container62">
      <dl>
        <dd>
          <dt>Tabla 1. Sensores utilizados.</dt>
          <dd className="containertabla1">
            <form className="sensortabla1" name="formularisensortabla1">
              <span>
                <label htmlFor="Tabla1sensor">Posicion</label>
                <label htmlFor="Tabla1sensor">1</label>
                <label htmlFor="Tabla1sensor">2</label>
                <label htmlFor="Tabla1sensor">3</label>
                <label htmlFor="Tabla1sensor">4</label>
                <label htmlFor="Tabla1sensor">5</label>
                <label htmlFor="Tabla1sensor">6</label>
                <label htmlFor="Tabla1sensor">7</label>
              </span>
              <span>
                <label htmlFor="Tabla1sensor">Sensor</label>
                <input
                  className="datalogop"
                  type="text"
                  id="Tabla1sensor"
                  placeholder="Datalogger de Temperatura"
                  name="Tabal1sensor"
                />
                <input
                  className="datalogop"
                  type="text"
                  id="Tabla1sensor"
                  placeholder="Datalogger de Temperatura"
                  name="Tabal1sensor"
                />
                <input
                  className="datalogop"
                  type="text"
                  id="Tabla1sensor"
                  placeholder="Datalogger de Temperatura"
                  name="Tabal1sensor"
                />
                <input
                  className="datalogop"
                  type="text"
                  id="Tabla1sensor"
                  placeholder="Datalogger de Temperatura"
                  name="Tabal1sensor"
                />
                <input
                  className="datalogop"
                  type="text"
                  id="Tabla1sensor"
                  placeholder="Datalogger de Temperatura"
                  name="Tabal1sensor"
                />
                <input
                  className="datalogop"
                  type="text"
                  id="Tabla1sensor"
                  placeholder="Datalogger de Temperatura"
                  name="Tabal1sensor"
                />
                <input
                  className="datalogop"
                  type="text"
                  id="Tabla1sensor"
                  placeholder="Datalogger de Temperatura"
                  name="Tabal1sensor"
                />
              </span>
              <span>
                <label htmlFor="Serietabla1">Serie</label>
                <input
                  className="serietabla1"
                  type="text"
                  id="Serietabla1"
                  placeholder="# serie"
                  name="Serietabla1"
                />
                <input
                  className="serietabla1"
                  type="text"
                  id="Serietabla1"
                  placeholder="# serie"
                  name="Serietabla1"
                />
                <input
                  className="serietabla1"
                  type="text"
                  id="Serietabla1"
                  placeholder="# serie"
                  name="Serietabla1"
                />
                <input
                  className="serietabla1"
                  type="text"
                  id="Serietabla1"
                  placeholder="# serie"
                  name="Serietabla1"
                />
                <input
                  className="serietabla1"
                  type="text"
                  id="Serietabla1"
                  placeholder="# serie"
                  name="Serietabla1"
                />
                <input
                  className="serietabla1"
                  type="text"
                  id="Serietabla1"
                  placeholder="# serie"
                  name="Serietabla1"
                />
                <input
                  className="serietabla1"
                  type="text"
                  id="Serietabla1"
                  placeholder="# serie"
                  name="Serietabla1"
                />
              </span>
            </form>
          </dd>
          <dt>
            Tabla 2. Resultados obtenidos calificación de la distribución de
            temperatura para periodos estables por sensor.
          </dt>
          <dd>
            Se evaluaron periodos estables de tiempo (excluye tiempos de pruebas
            de hold-over, simulaciones de carga, simulaciones de alarmas, ciclos
            de descongelamiento automático) los datos a continuación
            corresponden al cálculo sobre todas las lecturas de cada uno de los
            Dataloggers.
            <dt>Periodo 1.</dt>
            <dd>Periodo estable nocturno </dd>
            <dt>Periodo 2.</dt>
            <dd>Periodo estable diurno </dd>
            <dt>Periodo 3. </dt>
            <dd>Promedio total del monitoreo</dd>
          </dd>
          <dt>Sensor 1. + #serie</dt>
          <dd>
            <form className="sensor" name="formularisensor1">
              <span>
                <label htmlFor="Sensor1">Periodo</label>
                <label htmlFor="FechaSensor1">1</label>
                <label htmlFor="FechaSensor1">2</label>
                <label htmlFor="FechaSensor1">3</label>
              </span>
              <span>
                <label htmlFor="FechaSensor1">Fecha de Inicio</label>
                <input
                  className="FechaSensor1"
                  type="text"
                  id="FechaSensor1"
                  placeholder="01-Ene-2021"
                  name="FechaSensor1"
                />
                <input
                  className="FechaSensor1"
                  type="text"
                  id="FechaSensor1"
                  placeholder="01-Ene-2021"
                  name="FechaSensor1"
                />
                <input
                  className="FechaSensor1"
                  type="text"
                  id="FechaSensor1"
                  placeholder="01-Ene-2021"
                  name="FechaSensor1"
                />
              </span>
              <span>
                <label htmlFor="HoraSensor1">Hora de Inicio</label>
                <input
                  className="HoraSensor1"
                  type="text"
                  id="HoraSensor1"
                  placeholder="00:00:00"
                  name="HoraSensor1"
                />
                <input
                  className="HoraSensor1"
                  type="text"
                  id="HoraSensor1"
                  placeholder="00:00:00"
                  name="HoraSensor1"
                />
                <input
                  className="HoraSensor1"
                  type="text"
                  id="HoraSensor1"
                  placeholder="00:00:00"
                  name="HoraSensor1"
                />
              </span>
              <span>
                <label htmlFor="FechaFSensor1">Fecha de Final</label>
                <input
                  className="FechaFSensor1"
                  type="text"
                  id="FechaFSensor1"
                  placeholder="01-Ene-2021"
                  name="FechaFSensor1"
                />
                <input
                  className="FechaFSensor1"
                  type="text"
                  id="FechaFSensor1"
                  placeholder="01-Ene-2021"
                  name="FechaFSensor1"
                />
                <input
                  className="FechaFSensor1"
                  type="text"
                  id="FechaFSensor1"
                  placeholder="01-Ene-2021"
                  name="FechaFSensor1"
                />
              </span>
              <span>
                <label htmlFor="HoraFSensor1">Hora de Final</label>
                <input
                  className="HoraFSensor1"
                  type="text"
                  id="HoraFSensor1"
                  placeholder="00:00:00"
                  name="HoraFSensor1"
                />
                <input
                  className="HoraFSensor1"
                  type="text"
                  id="HoraFSensor1"
                  placeholder="00:00:00"
                  name="HoraFSensor1"
                />
                <input
                  className="HoraFSensor1"
                  type="text"
                  id="HoraFSensor1"
                  placeholder="00:00:00"
                  name="HoraFSensor1"
                />
              </span>
              <span>
                <label htmlFor="TemMaxSensor1">Temp. Máx. </label>
                <input
                  className="TemMaxSensor1"
                  type="text"
                  id="TemMaxSensor1"
                  placeholder="4,0"
                  name="TemMaxSensor1"
                />
                <input
                  className="TemMaxSensor1"
                  type="text"
                  id="TemMaxSensor1"
                  placeholder="4,0"
                  name="TemMaxSensor1"
                />
                <input
                  className="TemMaxSensor1"
                  type="text"
                  id="TemMaxSensor1"
                  placeholder="4,0"
                  name="TemMaxSensor1"
                />
              </span>
              <span>
                <label htmlFor="TemMinSensor1">Temp. Min. </label>
                <input
                  className="TemMinSensor1"
                  type="text"
                  id="TemMinSensor1"
                  placeholder="2,0"
                  name="TemMinSensor1"
                />
                <input
                  className="TemMinSensor1"
                  type="text"
                  id="TemMinSensor1"
                  placeholder="2,0"
                  name="TemMinSensor1"
                />
                <input
                  className="TemMinSensor1"
                  type="text"
                  id="TemMinSensor1"
                  placeholder="2,0"
                  name="TemMinSensor1"
                />
              </span>
              <span>
                <label htmlFor="TemPromSensor1">Temp. Promedio </label>
                <input
                  className="TemPromSensor1"
                  type="text"
                  id="TemPromSensor1"
                  placeholder="2,9"
                  name="TemPromSensor1"
                />
                <input
                  className="TemPromSensor1"
                  type="text"
                  id="TemPromSensor1"
                  placeholder="2,9"
                  name="TemPromSensor1"
                />
                <input
                  className="TemPromSensor1"
                  type="text"
                  id="TemPromSensor1"
                  placeholder="2,9"
                  name="TemPromSensor1"
                />
              </span>
              <span>
                <label htmlFor="DevEstSensor1">Desv. Estandar </label>
                <input
                  className="DevEstSensor1"
                  type="text"
                  id="DevEstSensor1"
                  placeholder="0,6"
                  name="DevEstSensor1"
                />
                <input
                  className="DevEstSensor1"
                  type="text"
                  id="DevEstSensor1"
                  placeholder="0,6"
                  name="DevEstSensor1"
                />
                <input
                  className="DevEstSensor1"
                  type="text"
                  id="DevEstSensor1"
                  placeholder="0,6"
                  name="DevEstSensor1"
                />
              </span>
              <span className="cumpleSensor">
                <label htmlFor="CumpleSensor1">Cumple</label>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
              </span>
            </form>
          </dd>
          <dt>Sensor 2. + #serie</dt>
          <dd>
            <form className="sensor" name="formularisensor1">
              <span>
                <label htmlFor="Sensor2">Periodo</label>
                <label htmlFor="FechaSensor2">1</label>
                <label htmlFor="FechaSensor2">2</label>
                <label htmlFor="FechaSensor2">3</label>
              </span>
              <span>
                <label htmlFor="FechaSensor2">Fecha de Inicio</label>
                <input
                  className="FechaSensor2"
                  type="text"
                  id="FechaSensor2"
                  placeholder="01-Ene-2021"
                  name="FechaSensor2"
                />
                <input
                  className="FechaSensor2"
                  type="text"
                  id="FechaSensor2"
                  placeholder="01-Ene-2021"
                  name="FechaSensor2"
                />
                <input
                  className="FechaSensor2"
                  type="text"
                  id="FechaSensor2"
                  placeholder="01-Ene-2021"
                  name="FechaSensor2"
                />
              </span>
              <span>
                <label htmlFor="HoraSensor2">Hora de Inicio</label>
                <input
                  className="HoraSensor2"
                  type="text"
                  id="HoraSensor2"
                  placeholder="00:00:00"
                  name="HoraSensor2"
                />
                <input
                  className="HoraSensor2"
                  type="text"
                  id="HoraSensor2"
                  placeholder="00:00:00"
                  name="HoraSensor2"
                />
                <input
                  className="HoraSensor2"
                  type="text"
                  id="HoraSensor2"
                  placeholder="00:00:00"
                  name="HoraSensor2"
                />
              </span>
              <span>
                <label htmlFor="FechaFSensor2">Fecha de Final</label>
                <input
                  className="FechaFSensor2"
                  type="text"
                  id="FechaFSensor2"
                  placeholder="01-Ene-2021"
                  name="FechaFSensor2"
                />
                <input
                  className="FechaFSensor2"
                  type="text"
                  id="FechaFSensor2"
                  placeholder="01-Ene-2021"
                  name="FechaFSensor2"
                />
                <input
                  className="FechaFSensor2"
                  type="text"
                  id="FechaFSensor2"
                  placeholder="01-Ene-2021"
                  name="FechaFSensor2"
                />
              </span>
              <span>
                <label htmlFor="HoraFSensor2">Hora de Final</label>
                <input
                  className="HoraFSensor2"
                  type="text"
                  id="HoraFSensor2"
                  placeholder="00:00:00"
                  name="HoraFSensor2"
                />
                <input
                  className="HoraFSensor2"
                  type="text"
                  id="HoraFSensor2"
                  placeholder="00:00:00"
                  name="HoraFSensor2"
                />
                <input
                  className="HoraFSensor2"
                  type="text"
                  id="HoraFSensor2"
                  placeholder="00:00:00"
                  name="HoraFSensor2"
                />
              </span>
              <span>
                <label htmlFor="TemMaxSensor2">Temp. Máx. </label>
                <input
                  className="TemMaxSensor2"
                  type="text"
                  id="TemMaxSensor2"
                  placeholder="4,0"
                  name="TemMaxSensor2"
                />
                <input
                  className="TemMaxSensor2"
                  type="text"
                  id="TemMaxSensor2"
                  placeholder="4,0"
                  name="TemMaxSensor2"
                />
                <input
                  className="TemMaxSensor2"
                  type="text"
                  id="TemMaxSensor2"
                  placeholder="4,0"
                  name="TemMaxSensor2"
                />
              </span>
              <span>
                <label htmlFor="TemMinSensor2">Temp. Min. </label>
                <input
                  className="TemMinSensor2"
                  type="text"
                  id="TemMinSensor2"
                  placeholder="2,0"
                  name="TemMinSensor2"
                />
                <input
                  className="TemMinSensor2"
                  type="text"
                  id="TemMinSensor2"
                  placeholder="2,0"
                  name="TemMinSensor2"
                />
                <input
                  className="TemMinSensor2"
                  type="text"
                  id="TemMinSensor2"
                  placeholder="2,0"
                  name="TemMinSensor2"
                />
              </span>
              <span>
                <label htmlFor="TemPromSensor2">Temp. Promedio </label>
                <input
                  className="TemPromSensor2"
                  type="text"
                  id="TemPromSensor2"
                  placeholder="2,9"
                  name="TemPromSensor2"
                />
                <input
                  className="TemPromSensor2"
                  type="text"
                  id="TemPromSensor2"
                  placeholder="2,9"
                  name="TemPromSensor2"
                />
                <input
                  className="TemPromSensor2"
                  type="text"
                  id="TemPromSensor2"
                  placeholder="2,9"
                  name="TemPromSensor2"
                />
              </span>
              <span>
                <label htmlFor="DevEstSensor2">Desv. Estandar </label>
                <input
                  className="DevEstSensor2"
                  type="text"
                  id="DevEstSensor2"
                  placeholder="0,6"
                  name="DevEstSensor2"
                />
                <input
                  className="DevEstSensor2"
                  type="text"
                  id="DevEstSensor2"
                  placeholder="0,6"
                  name="DevEstSensor2"
                />
                <input
                  className="DevEstSensor2"
                  type="text"
                  id="DevEstSensor2"
                  placeholder="0,6"
                  name="DevEstSensor2"
                />
              </span>
              <span className="cumpleSensor">
                <label htmlFor="CumpleSensor2">Cumple</label>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
                <span>
                  <label htmlFor="Si">
                    Si
                    <input type="radio" name="Si" id="Si" />
                  </label>
                  <label htmlFor="No">
                    No
                    <input type="radio" name="No" id="No" />
                  </label>
                </span>
              </span>
            </form>
          </dd>
        </dd>
        <dd>
        <dt>Sensor 3. + #serie</dt>
        <dd>
          <form className="sensor" name="formularisensor1">
            <span>
              <label htmlFor="Sensor3">Periodo</label>
              <label htmlFor="FechaSensor3">1</label>
              <label htmlFor="FechaSensor3">2</label>
              <label htmlFor="FechaSensor3">3</label>
            </span>
            <span>
              <label htmlFor="FechaSensor3">Fecha de Inicio</label>
              <input
                className="FechaSensor3"
                type="text"
                id="FechaSensor3"
                placeholder="01-Ene-2021"
                name="FechaSensor3"
              />
              <input
                className="FechaSensor3"
                type="text"
                id="FechaSensor3"
                placeholder="01-Ene-2021"
                name="FechaSensor3"
              />
              <input
                className="FechaSensor3"
                type="text"
                id="FechaSensor3"
                placeholder="01-Ene-2021"
                name="FechaSensor3"
              />
            </span>
            <span>
              <label htmlFor="HoraSensor3">Hora de Inicio</label>
              <input
                className="HoraSensor3"
                type="text"
                id="HoraSensor3"
                placeholder="00:00:00"
                name="HoraSensor3"
              />
              <input
                className="HoraSensor3"
                type="text"
                id="HoraSensor3"
                placeholder="00:00:00"
                name="HoraSensor3"
              />
              <input
                className="HoraSensor3"
                type="text"
                id="HoraSensor3"
                placeholder="00:00:00"
                name="HoraSensor3"
              />
            </span>
            <span>
              <label htmlFor="FechaFSensor3">Fecha de Final</label>
              <input
                className="FechaFSensor3"
                type="text"
                id="FechaFSensor3"
                placeholder="01-Ene-2021"
                name="FechaFSensor3"
              />
              <input
                className="FechaFSensor3"
                type="text"
                id="FechaFSensor3"
                placeholder="01-Ene-2021"
                name="FechaFSensor3"
              />
              <input
                className="FechaFSensor3"
                type="text"
                id="FechaFSensor3"
                placeholder="01-Ene-2021"
                name="FechaFSensor3"
              />
            </span>
            <span>
              <label htmlFor="HoraFSensor3">Hora de Final</label>
              <input
                className="HoraFSensor3"
                type="text"
                id="HoraFSensor3"
                placeholder="00:00:00"
                name="HoraFSensor3"
              />
              <input
                className="HoraFSensor3"
                type="text"
                id="HoraFSensor3"
                placeholder="00:00:00"
                name="HoraFSensor3"
              />
              <input
                className="HoraFSensor3"
                type="text"
                id="HoraFSensor3"
                placeholder="00:00:00"
                name="HoraFSensor3"
              />
            </span>
            <span>
              <label htmlFor="TemMaxSensor3">Temp. Máx. </label>
              <input
                className="TemMaxSensor3"
                type="text"
                id="TemMaxSensor3"
                placeholder="4,0"
                name="TemMaxSensor3"
              />
              <input
                className="TemMaxSensor3"
                type="text"
                id="TemMaxSensor3"
                placeholder="4,0"
                name="TemMaxSensor3"
              />
              <input
                className="TemMaxSensor3"
                type="text"
                id="TemMaxSensor3"
                placeholder="4,0"
                name="TemMaxSensor3"
              />
            </span>
            <span>
              <label htmlFor="TemMinSensor3">Temp. Min. </label>
              <input
                className="TemMinSensor3"
                type="text"
                id="TemMinSensor3"
                placeholder="2,0"
                name="TemMinSensor3"
              />
              <input
                className="TemMinSensor3"
                type="text"
                id="TemMinSensor3"
                placeholder="2,0"
                name="TemMinSensor3"
              />
              <input
                className="TemMinSensor3"
                type="text"
                id="TemMinSensor3"
                placeholder="2,0"
                name="TemMinSensor3"
              />
            </span>
            <span>
              <label htmlFor="TemPromSensor3">Temp. Promedio </label>
              <input
                className="TemPromSensor3"
                type="text"
                id="TemPromSensor3"
                placeholder="2,9"
                name="TemPromSensor3"
              />
              <input
                className="TemPromSensor3"
                type="text"
                id="TemPromSensor3"
                placeholder="2,9"
                name="TemPromSensor3"
              />
              <input
                className="TemPromSensor3"
                type="text"
                id="TemPromSensor3"
                placeholder="2,9"
                name="TemPromSensor3"
              />
            </span>
            <span>
              <label htmlFor="DevEstSensor3">Desv. Estandar </label>
              <input
                className="DevEstSensor3"
                type="text"
                id="DevEstSensor3"
                placeholder="0,6"
                name="DevEstSensor3"
              />
              <input
                className="DevEstSensor3"
                type="text"
                id="DevEstSensor3"
                placeholder="0,6"
                name="DevEstSensor3"
              />
              <input
                className="DevEstSensor3"
                type="text"
                id="DevEstSensor3"
                placeholder="0,6"
                name="DevEstSensor3"
              />
            </span>
            <span className="cumpleSensor">
              <label htmlFor="CumpleSensor3">Cumple</label>
              <span>
                <label htmlFor="Si">
                  Si
                  <input type="radio" name="Si" id="Si" />
                </label>
                <label htmlFor="No">
                  No
                  <input type="radio" name="No" id="No" />
                </label>
              </span>
              <span>
                <label htmlFor="Si">
                  Si
                  <input type="radio" name="Si" id="Si" />
                </label>
                <label htmlFor="No">
                  No
                  <input type="radio" name="No" id="No" />
                </label>
              </span>
              <span>
                <label htmlFor="Si">
                  Si
                  <input type="radio" name="Si" id="Si" />
                </label>
                <label htmlFor="No">
                  No
                  <input type="radio" name="No" id="No" />
                </label>
              </span>
            </span>
          </form>
        </dd>
        </dd>
      </dl>
    </div>
  );
};
